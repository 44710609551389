import { Line } from "../../../../trackTypes";

const victoriaSquare = [
  [-34.92864, 138.60002],
  [-34.92860, 138.60076]
];

const wakefieldStreet = [
  [-34.92860, 138.60076],
  [-34.92805, 138.61184]
];

const wakefieldStreetWest = [
  [-34.92805, 138.61184],
  [-34.92760, 138.62033],
  [-34.92759, 138.62085],
  [-34.92761, 138.62133],
  [-34.92778, 138.62282],
  [-34.92786, 138.62334]
];

const dequettevilleTerrace = [
  [-34.92222, 138.61453],
  [-34.92240, 138.61501],
  [-34.92253, 138.61545],
  [-34.92275, 138.61594],
  [-34.92765, 138.62282],
  [-34.92786, 138.62334]
];

const britanniaHotel = [
  [-34.92786, 138.62334],
  [-34.92786, 138.62403]
];

const shipstersRoad = [
  [-34.92786, 138.62403],
  [-34.92650, 138.64957]
];

const burnside = [
  [-34.92647, 138.65006],
  [-34.92643, 138.65082],
  [-34.92647, 138.65098],
  [-34.92659, 138.65106],
  [-34.93165, 138.65143],
  [-34.93173, 138.65150],
  [-34.93175, 138.65163],
  [-34.93152, 138.65617],
  [-34.93155, 138.65638],
  [-34.93162, 138.65652],
  [-34.93174, 138.65659],
  [-34.93193, 138.65664],
  [-34.93290, 138.65671],
  [-34.93302, 138.65676],
  [-34.93309, 138.65688],
  [-34.93311, 138.65701],
  [-34.93297, 138.65956],
  [-34.93302, 138.65967],
  [-34.93310, 138.65974],
  [-34.93807, 138.66012]
];

const erindale = [
  [-34.92643, 138.65083],
  [-34.92576, 138.66397]
];

const brigalowAve = [
  [-34.92576, 138.66397],
  [-34.92559, 138.66731]
];

const wakefielRoadJunctions = [
  [-34.92832, 138.60624],
  [-34.92826, 138.60610],
  [-34.92821, 138.60604],
  [-34.92812, 138.60597],
  [-34.92820, 138.60593],
  [-34.92828, 138.60585],
  [-34.92835, 138.60570],
  [-34.92839, 138.60585],
  [-34.92845, 138.60593],
  [-34.92855, 138.60600]
];

export const east3: Line = {
  name: 'East3',
  state: 'SA',
  segments: [
    {
      segments: [victoriaSquare],
      history: {
        opened: {
          date: '1909-10-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-11-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wakefieldStreet, wakefielRoadJunctions],
      history: {
        opened: {
          date: '1909-10-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wakefieldStreetWest],
      history: {
        opened: {
          date: '1909-10-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-05-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [dequettevilleTerrace],
      history: {
        opened: {
          date: '1883-04-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-10-23',
          status: 'closed'
        }]
      }
    },
    {
      segments: [britanniaHotel],
      history: {
        opened: {
          date: '1883-04-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-11-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1952-05-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [shipstersRoad],
      history: {
        opened: {
          date: '1883-07-03',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-11-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1952-05-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [burnside],
      history: {
        opened: {
          date: '1883-10-20',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1911-09-03',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1952-05-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [erindale],
      history: {
        opened: {
          date: '1944-01-05',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1952-05-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [brigalowAve],
      history: {
        opened: {
          date: '1944-01-14',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1952-05-01',
          status: 'closed'
        }]
      }
    }
  ]
};
