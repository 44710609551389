import { Line } from "../../../../trackTypes";

const seatounStart = [
  [-41.32534, 174.81161],
  [-41.32511, 174.81833],
  [-41.32510, 174.81847],
  [-41.32507, 174.81860],
  [-41.32473, 174.81969],
  [-41.32464, 174.82001],
  [-41.32449, 174.82071],
  [-41.32402, 174.82283],
  [-41.32397, 174.82314],
  [-41.32395, 174.82336],
  [-41.32395, 174.82356],
  [-41.32397, 174.82371],
  [-41.32423, 174.82474],
  [-41.32429, 174.82509],
  [-41.32449, 174.82668],
  [-41.32450, 174.82688],
  [-41.32447, 174.82703],
  [-41.32443, 174.82716],
  [-41.32438, 174.82723],
  [-41.32429, 174.82735]
];

const seatounTunnel = [
  [-41.32429, 174.82735],
  [-41.32332, 174.82850]
];

const seatounEnd = [
  [-41.32332, 174.82850],
  [-41.32325, 174.82857],
  [-41.32315, 174.82863],
  [-41.32258, 174.82885],
  [-41.32251, 174.82890],
  [-41.32245, 174.82898],
  [-41.32239, 174.82908],
  [-41.32236, 174.82918],
  [-41.32237, 174.82931],
  [-41.32287, 174.83193]
];

export const line4: Line = {
  name: 'Line 4',
  state: 'NZ',
  segments: [
    {
      segments: [
        seatounStart,
        {
          name: 'Seatoun Tunnel',
          segment: seatounTunnel,
          type: 'tunnel'
        },
        seatounEnd
      ],
      history: {
        opened: {
          date: '1907-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
