import { Line } from "../../trackTypes";

const line = [
  [-33.87028, 151.19886],
  [-33.87105, 151.20331],
  [-33.87105, 151.20350],
  [-33.87098, 151.20536],
  [-33.87093, 151.20549],
  [-33.87082, 151.20569],
  [-33.87078, 151.20583],
  [-33.87071, 151.20797],
  [-33.87074, 151.20807],
  [-33.87077, 151.20813],
  [-33.87082, 151.20817],
  [-33.87089, 151.20819],
  [-33.87300, 151.20810],
  [-33.87653, 151.20782],
  [-33.87659, 151.20777],
  [-33.87661, 151.20772],
  [-33.87664, 151.20764],
  [-33.87664, 151.20755],
  [-33.87638, 151.20601],
  [-33.87608, 151.20386],
  [-33.87610, 151.20371],
  [-33.87613, 151.20364],
  [-33.87618, 151.20357],
  [-33.87624, 151.20353],
  [-33.87719, 151.20334],
  [-33.87741, 151.20330],
  [-33.87762, 151.20328],
  [-33.87869, 151.20344],
  [-33.87881, 151.20341],
  [-33.87894, 151.20332],
  [-33.87903, 151.20320],
  [-33.87913, 151.20267],
  [-33.87914, 151.20253],
  [-33.87913, 151.20241],
  [-33.87900, 151.20137],
  [-33.87895, 151.20122],
  [-33.87891, 151.20113],
  [-33.87877, 151.20104],
  [-33.87796, 151.20060],
  [-33.87788, 151.20054],
  [-33.87731, 151.20002],
  [-33.87720, 151.19991],
  [-33.87481, 151.19855],
  [-33.87444, 151.19843],
  [-33.87414, 151.19836],
  [-33.87353, 151.19831],
  [-33.87275, 151.19824],
  [-33.87232, 151.19828],
  [-33.87216, 151.19835],
  [-33.87205, 151.19838],
  [-33.87095, 151.19845],
  [-33.87079, 151.19845],
  [-33.87061, 151.19844],
  [-33.87050, 151.19848],
  [-33.87038, 151.19864],
  [-33.87032, 151.19875],
  [-33.87031, 151.19879],
  [-33.87028, 151.19886]
];

export const sydneyMonorail: Line = {
  name: 'Sydney Monorail',
  state: 'NSW',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1988-07-21',
          tracks: 1,
          gauge: 'standard',
          trackType: 'monorail'
        },
        trackChange: [{
          date: '2013-06-30',
          status: 'closed'
        }]
      }
    }
  ]
};
