import { Line } from "../../../../trackTypes";

const pencarrowAvenue = [
  [-36.86495, 174.76067],
  [-36.86509, 174.76068],
  [-36.86518, 174.76069],
  [-36.86528, 174.76073],
  [-36.86589, 174.76134],
  [-36.86602, 174.76144],
  [-36.86612, 174.76150],
  [-36.86680, 174.76177],
  [-36.86697, 174.76180],
  [-36.86714, 174.76177],
  [-36.86725, 174.76171],
  [-36.86769, 174.76131],
  [-36.86792, 174.76111],
  [-36.86944, 174.76032],
  [-36.86957, 174.76028],
  [-36.86965, 174.76028],
  [-36.87003, 174.76035],
  [-36.87017, 174.76041],
  [-36.87029, 174.76050],
  [-36.87179, 174.76196],
  [-36.87189, 174.76204],
  [-36.87201, 174.76209],
  [-36.87213, 174.76211],
  [-36.87231, 174.76210],
  [-36.87271, 174.76204],
  [-36.87285, 174.76204],
  [-36.87329, 174.76214],
  [-36.87348, 174.76216],
  [-36.87434, 174.76218],
  [-36.87448, 174.76216],
  [-36.87461, 174.76210],
  [-36.87478, 174.76195],
  [-36.87498, 174.76182],
  [-36.87516, 174.76172],
  [-36.87540, 174.76164],
  [-36.87553, 174.76161],
  [-36.87573, 174.76159],
  [-36.87605, 174.76160],
  [-36.87616, 174.76160],
  [-36.87627, 174.76158],
  [-36.87700, 174.76130],
  [-36.87714, 174.76126],
  [-36.87733, 174.76125],
  [-36.87857, 174.76133],
  [-36.87872, 174.76134],
  [-36.87883, 174.76133],
  [-36.87925, 174.76125],
  [-36.87942, 174.76124],
  [-36.87955, 174.76127],
  [-36.87973, 174.76136],
  [-36.88052, 174.76186],
  [-36.88061, 174.76192],
  [-36.88076, 174.76195],
  [-36.88238, 174.76214],
  [-36.88249, 174.76213],
  [-36.88257, 174.76211],
  [-36.88273, 174.76204],
  [-36.88324, 174.76172],
  [-36.88394, 174.76127],
  [-36.88416, 174.76115],
  [-36.88433, 174.76108],
  [-36.88453, 174.76104],
  [-36.88472, 174.76105],
  [-36.88528, 174.76120],
  [-36.88548, 174.76122],
  [-36.88569, 174.76118],
  [-36.88790, 174.76052]
];

const threeKings = [
  [-36.88790, 174.76052],
  [-36.89032, 174.75978],
  [-36.89482, 174.75840],
  [-36.89822, 174.75731],
  [-36.89837, 174.75724],
  [-36.89865, 174.75708],
  [-36.89882, 174.75700],
  [-36.89936, 174.75681],
  [-36.89949, 174.75680],
  [-36.89961, 174.75684],
  [-36.89973, 174.75692],
  [-36.90177, 174.75914],
  [-36.90187, 174.75922],
  [-36.90202, 174.75929],
  [-36.90219, 174.75933],
  [-36.90293, 174.75946],
  [-36.90308, 174.75946],
  [-36.90880, 174.75864]
];

export const line5: Line = {
  name: 'Line 5',
  state: 'NZ',
  segments: [
    {
      segments: [pencarrowAvenue],
      history: {
        opened: {
          date: '1908-05-16',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-05-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [threeKings],
      history: {
        opened: {
          date: '1931-03-28',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-05-22',
          status: 'closed'
        }]
      }
    }
  ]
}
