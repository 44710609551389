import { line1 } from "./aucklandTrams/line1";
import { line10 } from "./aucklandTrams/line10";
import { line11 } from "./aucklandTrams/line11";
import { line12 } from "./aucklandTrams/line12";
import { line2 } from "./aucklandTrams/line2";
import { line3 } from "./aucklandTrams/line3";
import { line4 } from "./aucklandTrams/line4";
import { line5 } from "./aucklandTrams/line5";
import { line6 } from "./aucklandTrams/line6";
import { line7 } from "./aucklandTrams/line7";
import { line8 } from "./aucklandTrams/line8";
import { line9 } from "./aucklandTrams/line9";

export const aucklandTrams = [
  line1,
  line2,
  line3,
  line4,
  line5,
  line6,
  line7,
  line8,
  line9,
  line10,
  line11,
  line12
];
