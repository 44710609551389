import { Line } from "../../../../trackTypes";

const anzacAvenue = [
  [-36.84561, 174.77107],
  [-36.84575, 174.77137],
  [-36.84584, 174.77145],
  [-36.84597, 174.77152],
  [-36.84706, 174.77210],
  [-36.84724, 174.77221],
  [-36.84752, 174.77245],
  [-36.84814, 174.77316],
  [-36.84831, 174.77332],
  [-36.84856, 174.77347],
  [-36.84879, 174.77355],
  [-36.84889, 174.77356],
  [-36.84903, 174.77355],
  [-36.84919, 174.77351],
  [-36.84942, 174.77336],
  [-36.84956, 174.77322],
  [-36.85185, 174.77039],
  [-36.85210, 174.77014],
  [-36.85408, 174.76816]
];

const queenStreetSouth = [
  [-36.85075, 174.76459],
  [-36.85194, 174.76404],
  [-36.85765, 174.76155]
];

const pittStreetSouth = [
  [-36.84783, 174.76173],
  [-36.84999, 174.76078],
  [-36.85086, 174.76040],
  [-36.85107, 174.76029],
  [-36.85370, 174.75859],
  [-36.85384, 174.75855],
  [-36.85393, 174.75854],
  [-36.85404, 174.75853],
  [-36.85692, 174.75895]
];

const pittStreetLink = [
  [-36.85692, 174.75895],
  [-36.85751, 174.75904],
  [-36.85757, 174.75899],
  [-36.85761, 174.75895],
  [-36.85766, 174.75880]
];

const wellesleyStreetWest = [
  [-36.84999, 174.76078],
  [-36.84994, 174.76087],
  [-36.84991, 174.76096],
  [-36.84990, 174.76106],
  [-36.85083, 174.76427],
  [-36.85095, 174.76470]
];

const karangahapeRoadWest = [
  [-36.85766, 174.75880],
  [-36.85790, 174.76042],
  [-36.85792, 174.76072],
  [-36.85792, 174.76116]
];

const karangahapeRoadEast = [
  [-36.85788, 174.76170],
  [-36.85789, 174.76197],
  [-36.85791, 174.76209],
  [-36.85794, 174.76222],
  [-36.85834, 174.76334],
  [-36.85839, 174.76340],
  [-36.85846, 174.76344],
  [-36.85857, 174.76346],
  [-36.85869, 174.76343]
];

const karangahapeRoadJunction = [
  [-36.85792, 174.76116],
  [-36.85786, 174.76133],
  [-36.85780, 174.76142],
  [-36.85765, 174.76155],
  [-36.85777, 174.76155],
  [-36.85781, 174.76158],
  [-36.85785, 174.76164],
  [-36.85788, 174.76170]
];

const karangahapeRoadLink = [
  [-36.85792, 174.76116],
  [-36.85788, 174.76170]
];

const whyndamStreet = [
  [-36.84650, 174.76233],
  [-36.84643, 174.76231],
  [-36.84639, 174.76229],
  [-36.84634, 174.76222],
  [-36.84620, 174.76171]
];

const beresfordStreet = [
  [-36.85692, 174.75895],
  [-36.85701, 174.75891],
  [-36.85706, 174.75883],
  [-36.85707, 174.75875],
  [-36.85706, 174.75864],
  [-36.85671, 174.75788]
];

const queenStreetGrandJunctionTopLeft = [
  [-36.85075, 174.76459],
  [-36.85080, 174.76449],
  [-36.85082, 174.76440],
  [-36.85083, 174.76427]
];

const queenStreetGrandJunction = [
  [-36.85083, 174.76427],
  [-36.85089, 174.76435],
  [-36.85096, 174.76440],
  [-36.85108, 174.76442],
  [-36.85101, 174.76453],
  [-36.85098, 174.76460],
  [-36.85095, 174.76470]
];

const queenStreetNorthSouthWestJunction = [
  [-36.84460, 174.76638],
  [-36.84468, 174.76649],
  [-36.84475, 174.76653],
  [-36.84487, 174.76655]
];

const queenStreetNorthSouthEastJunction = [
  [-36.84472, 174.76685],
  [-36.84474, 174.76672],
  [-36.84479, 174.76664],
  [-36.84487, 174.76655]
];

export const line12: Line = {
  name: 'Line 12',
  state: 'NZ',
  segments: [
    {
      segments: [anzacAvenue],
      history: {
        opened: {
          date: '1921-02-07',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1955-01-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [queenStreetSouth, queenStreetGrandJunction],
      history: {
        opened: {
          date: '1902-11-24',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1916-10-23',
          status: 'closed'
        }]
      }
    },
    {
      segments: [queenStreetGrandJunctionTopLeft],
      history: {
        opened: {
          date: '1902-11-24',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-11-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pittStreetSouth],
      history: {
        opened: {
          date: '1902-11-24',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1954-04-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pittStreetLink, queenStreetNorthSouthWestJunction],
      history: {
        opened: {
          date: '1902-11-24',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-11-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [wellesleyStreetWest],
      history: {
        opened: {
          date: '1902-11-24',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-11-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [karangahapeRoadEast, karangahapeRoadWest, karangahapeRoadLink],
      history: {
        opened: {
          date: '1902-11-24',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-11-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [karangahapeRoadJunction],
      history: {
        opened: {
          date: '1902-11-24',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1916-10-23',
          status: 'closed'
        }]
      }
    },
    {
      segments: [whyndamStreet, beresfordStreet],
      history: {
        opened: {
          date: '1936-05-06',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1954-04-30',
          status: 'closed'
        }]
      }
    },
    {
      segments: [queenStreetNorthSouthEastJunction],
      history: {
        opened: {
          date: '1903-01-31',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1954-04-30',
          status: 'closed'
        }]
      }
    }
  ]
}
