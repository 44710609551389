import { Line } from "../../../../trackTypes";

const city = [
  [-41.28145, 174.77601],
  [-41.28137, 174.77605],
  [-41.28133, 174.77610],
  [-41.28129, 174.77614],
  [-41.27990, 174.77878],
  [-41.27973, 174.77904],
  [-41.27970, 174.77913],
  [-41.27969, 174.77923],
  [-41.27971, 174.77934],
  [-41.28011, 174.78010],
  [-41.28019, 174.78016],
  [-41.28025, 174.78018],
  [-41.28032, 174.78018],
  [-41.28039, 174.78015],
  [-41.28153, 174.77910],
  [-41.28213, 174.77863],
  [-41.28654, 174.77619]
];

const cityLink = [
  [-41.28520, 174.77567],
  [-41.28528, 174.77575],
  [-41.28536, 174.77588],
  [-41.28591, 174.77771],
  [-41.28596, 174.77780],
  [-41.28603, 174.77786]
];

const aroStreet = [
  [-41.28359, 174.77546],
  [-41.28427, 174.77544],
  [-41.28442, 174.77543],
  [-41.28454, 174.77545],
  [-41.28479, 174.77551],
  [-41.28518, 174.77565],
  [-41.28531, 174.77570],
  [-41.28634, 174.77619],
  [-41.28644, 174.77621],
  [-41.28654, 174.77619],
  [-41.28822, 174.77525],
  [-41.29325, 174.77241],
  [-41.29609, 174.77081],
  [-41.29614, 174.77073],
  [-41.29617, 174.77062],
  [-41.29618, 174.77048],
  [-41.29544, 174.76807],
  [-41.29540, 174.76790],
  [-41.29516, 174.76398],
  [-41.29514, 174.76385],
  [-41.29509, 174.76374],
  [-41.29471, 174.76329],
  [-41.29463, 174.76315],
  [-41.29358, 174.76103]
];

const brooklyn = [
  [-41.29609, 174.77081],
  [-41.29712, 174.77023],
  [-41.29721, 174.77012],
  [-41.29725, 174.76999],
  [-41.29733, 174.76958],
  [-41.29741, 174.76941],
  [-41.29795, 174.76883],
  [-41.29810, 174.76875],
  [-41.29829, 174.76870],
  [-41.29980, 174.76851],
  [-41.30001, 174.76850],
  [-41.30014, 174.76855],
  [-41.30029, 174.76866],
  [-41.30057, 174.76904],
  [-41.30066, 174.76916],
  [-41.30079, 174.76927],
  [-41.30095, 174.76936],
  [-41.30214, 174.76967],
  [-41.30229, 174.76967],
  [-41.30242, 174.76966],
  [-41.30319, 174.76922],
  [-41.30339, 174.76900],
  [-41.30344, 174.76876],
  [-41.30339, 174.76849],
  [-41.30326, 174.76826],
  [-41.30309, 174.76811],
  [-41.30283, 174.76798],
  [-41.30264, 174.76788],
  [-41.30253, 174.76772],
  [-41.30246, 174.76752],
  [-41.30242, 174.76730],
  [-41.30244, 174.76705],
  [-41.30249, 174.76686],
  [-41.30270, 174.76626],
  [-41.30275, 174.76601],
  [-41.30276, 174.76574],
  [-41.30274, 174.76446],
  [-41.30278, 174.76423],
  [-41.30284, 174.76413],
  [-41.30292, 174.76406],
  [-41.30325, 174.76392],
  [-41.30372, 174.76368],
  [-41.30391, 174.76360],
  [-41.30516, 174.76330],
  [-41.30526, 174.76331],
  [-41.30530, 174.76337],
  [-41.30532, 174.76347],
  [-41.30528, 174.76364]
];

export const line2: Line = {
  name: 'Line 2',
  state: 'NZ',
  segments: [
    {
      segments: [aroStreet, city, cityLink],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [brooklyn],
      history: {
        opened: {
          date: '1906-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
