import { Line } from "../../../../trackTypes";

const station = [
  [-36.84353, 174.76717],
  [-36.84449, 174.76673]
];

const city = [
  [-36.84449, 174.76673],
  [-36.84455, 174.76665],
  [-36.84458, 174.76657],
  [-36.84460, 174.76638],
  [-36.84425, 174.76503],
  [-36.84423, 174.76478],
  [-36.84426, 174.76445],
  [-36.84443, 174.76344],
  [-36.84449, 174.76333],
  [-36.84453, 174.76329],
  [-36.84460, 174.76322],
  [-36.84627, 174.76244],
  [-36.84783, 174.76173]
];

const line = [
  [-36.84783, 174.76173],
  [-36.84788, 174.76163],
  [-36.84790, 174.76155],
  [-36.84790, 174.76146],
  [-36.84770, 174.76067],
  [-36.84765, 174.76045],
  [-36.84764, 174.76018],
  [-36.84766, 174.75998],
  [-36.84813, 174.75709],
  [-36.84815, 174.75675],
  [-36.84812, 174.75611],
  [-36.84809, 174.75224],
  [-36.84812, 174.75183],
  [-36.84846, 174.74843],
  [-36.84847, 174.74822],
  [-36.84845, 174.74804],
  [-36.84838, 174.74782],
  [-36.84755, 174.74589],
  [-36.84750, 174.74574],
  [-36.84748, 174.74559],
  [-36.84741, 174.74440],
  [-36.84744, 174.74427],
  [-36.84750, 174.74420],
  [-36.84755, 174.74414],
  [-36.84837, 174.74386],
  [-36.84847, 174.74385],
  [-36.84859, 174.74387],
  [-36.85348, 174.74544],
  [-36.85633, 174.74675],
  [-36.85645, 174.74682],
  [-36.85650, 174.74686],
  [-36.85959, 174.75255],
  [-36.85961, 174.75268],
  [-36.85961, 174.75281],
  [-36.85958, 174.75295]
];

const herneBay = [
  [-36.84741, 174.74440],
  [-36.84731, 174.74420],
  [-36.84506, 174.74044],
  [-36.84502, 174.74034],
  [-36.84501, 174.74023],
  [-36.84500, 174.74010],
  [-36.84526, 174.73719],
  [-36.84552, 174.73432],
  [-36.84555, 174.73419],
  [-36.84562, 174.73408],
  [-36.84819, 174.73108],
  [-36.84825, 174.73098],
  [-36.84828, 174.73085],
  [-36.84827, 174.72852],
  [-36.84830, 174.72828],
  [-36.84834, 174.72812],
  [-36.84840, 174.72797],
  [-36.84889, 174.72726],
  [-36.84890, 174.72720],
  [-36.84890, 174.72713],
  [-36.84890, 174.72706],
  [-36.84808, 174.72483]
];

const fanshawStreet = [
  [-36.84443, 174.76344],
  [-36.84448, 174.76304],
  [-36.84452, 174.76287],
  [-36.84492, 174.76200],
  [-36.84506, 174.76185],
  [-36.84526, 174.76160],
  [-36.84533, 174.76147],
  [-36.84543, 174.76125],
  [-36.84554, 174.76087],
  [-36.84621, 174.75682]
];

const grantStreet = [
  [-36.84621, 174.75682],
  [-36.84614, 174.75666],
  [-36.84602, 174.75653],
  [-36.84592, 174.75646],
  [-36.84579, 174.75647],
  [-36.84563, 174.75651],
  [-36.84505, 174.75669],
  [-36.84496, 174.75668],
  [-36.84491, 174.75665],
  [-36.84481, 174.75656],
  [-36.84437, 174.75492]
];

const halseyStreet = [
  [-36.84621, 174.75682],
  [-36.84631, 174.75666],
  [-36.84637, 174.75660],
  [-36.84651, 174.75652],
  [-36.84673, 174.75648],
  [-36.84792, 174.75639],
  [-36.84801, 174.75633],
  [-36.84808, 174.75622],
  [-36.84812, 174.75611]
];

const grantStreetJunction = [
  [-36.84592, 174.75646],
  [-36.84628, 174.75651],
  [-36.84651, 174.75652]
];

export const line1: Line = {
  name: 'Line 1',
  state: 'NZ',
  segments: [
    {
      segments: [station],
      history: {
        opened: {
          date: '1884-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1902-11-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1956-01-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [city],
      history: {
        opened: {
          date: '1884-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1902-11-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1954-11-09',
          status: 'closed'
        }]
      }
    },
    {
      segments: [line],
      history: {
        opened: {
          date: '1884-01-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1902-11-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1951-11-09',
          status: 'closed'
        }]
      }
    },
    {
      segments: [herneBay],
      history: {
        opened: {
          date: '1903-10-27',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1949-09-23',
          status: 'closed'
        }]
      }
    },
    {
      segments: [fanshawStreet, grantStreet],
      history: {
        opened: {
          date: '1925-11-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1954-11-04',
          status: 'closed'
        }]
      }
    },
    {
      segments: [grantStreetJunction, halseyStreet],
      history: {
        opened: {
          date: '1925-11-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1951-11-09',
          status: 'closed'
        }]
      }
    }
  ]
}
