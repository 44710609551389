import { Line } from "../../../../trackTypes";

const flindersStreetLeft = [
  [-34.92712, 138.59986],
  [-34.92711, 138.60079]
];

const flindersStreetRight = [
  [-34.92711, 138.60079],
  [-34.92686, 138.60572],
  [-34.92688, 138.60578],
  [-34.92693, 138.60585],
  [-34.92697, 138.60588]
];

const pulteneyStreet = [
  [-34.92697, 138.60588],
  [-34.92834, 138.60599]
];

const mitcham = [
  [-34.96511, 138.60859],
  [-34.97399, 138.60924],
  [-34.97411, 138.60930],
  [-34.97415, 138.60942],
  [-34.97420, 138.60968],
  [-34.97425, 138.60983],
  [-34.97432, 138.60997],
  [-34.97467, 138.61040],
  [-34.97720, 138.61415],
  [-34.97728, 138.61435],
  [-34.97732, 138.61454],
  [-34.97717, 138.61815],
  [-34.97720, 138.61850],
  [-34.97712, 138.61994],
  [-34.97713, 138.62009],
  [-34.97717, 138.62018],
  [-34.97728, 138.62033],
  [-34.97739, 138.62039],
  [-34.97907, 138.62052],
  [-34.97913, 138.62057],
  [-34.97917, 138.62061],
  [-34.97918, 138.62070],
  [-34.97906, 138.62311]
];

const pulteneyStreetSouth = [
  [-34.92834, 138.60599],
  [-34.93107, 138.60619],
];

const hurtleSquare = [
  [-34.93107, 138.60619],
  [-34.93338, 138.60637]
];

const hurtleSquareLoopLeft = [
  [-34.93107, 138.60619],
  [-34.93115, 138.60613],
  [-34.93121, 138.60601],
  [-34.93123, 138.60558],
  [-34.93127, 138.60551],
  [-34.93129, 138.60547],
  [-34.93135, 138.60545],
  [-34.93311, 138.60559],
  [-34.93319, 138.60564],
  [-34.93324, 138.60571],
  [-34.93325, 138.60580],
  [-34.93323, 138.60618],
  [-34.93326, 138.60628],
  [-34.93332, 138.60632],
  [-34.93338, 138.60637]
];

const hurtleSquareLoopRight = [
  [-34.93107, 138.60619],
  [-34.93112, 138.60627],
  [-34.93116, 138.60632],
  [-34.93119, 138.60641],
  [-34.93117, 138.60680],
  [-34.93121, 138.60691],
  [-34.93125, 138.60696],
  [-34.93132, 138.60698],
  [-34.93304, 138.60712],
  [-34.93313, 138.60709],
  [-34.93317, 138.60704],
  [-34.93320, 138.60695],
  [-34.93321, 138.60653],
  [-34.93325, 138.60646],
  [-34.93330, 138.60642],
  [-34.93338, 138.60637]
];

const unley = [
  [-34.93338, 138.60637],
  [-34.93553, 138.60651],
  [-34.94113, 138.60674],
  [-34.95494, 138.60782],
  [-34.96002, 138.60825],
  [-34.96511, 138.60859]
];

const unleyOval = [
  [-34.95042, 138.60747],
  [-34.95047, 138.60750],
  [-34.95050, 138.60760],
  [-34.95034, 138.61076]
];

export const south1: Line = {
  name: 'South1',
  state: 'SA',
  segments: [
    {
      segments: [pulteneyStreet],
      history: {
        opened: {
          date: '1879-02-13',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-10-23',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pulteneyStreetSouth],
      history: {
        opened: {
          date: '1879-02-13',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-11-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [flindersStreetLeft],
      history: {
        opened: {
          date: '1879-02-13',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-11-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [flindersStreetRight],
      history: {
        opened: {
          date: '1879-02-13',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-11-01',
          status: 'closed'
        }, {
          date: '1921-05-01',
          status: 'open',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-03-05',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mitcham],
      history: {
        opened: {
          date: '1879-02-13',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1911-07-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [unley],
      history: {
        opened: {
          date: '1879-02-13',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-11-01',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hurtleSquare],
      history: {
        opened: {
          date: '1909-11-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hurtleSquareLoopLeft],
      history: {
        opened: {
          date: '1879-02-13',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-11-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hurtleSquareLoopRight],
      history: {
        opened: {
          date: '1892-01-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-11-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [unleyOval],
      history: {
        opened: {
          date: '1910-05-28',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    }
  ]
};
