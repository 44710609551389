import { Line } from "../../../../trackTypes";

const line = [
  [-36.84449, 174.76673],
  [-36.84487, 174.76655],
  [-36.84589, 174.76627],
  [-36.84834, 174.76552],
  [-36.84913, 174.76529],
  [-36.84958, 174.76511],
  [-36.85075, 174.76459],
  [-36.85083, 174.76460],
  [-36.85088, 174.76463],
  [-36.85095, 174.76470],
  [-36.85110, 174.76523],
  [-36.85119, 174.76543],
  [-36.85126, 174.76556],
  [-36.85259, 174.76711],
  [-36.85276, 174.76728],
  [-36.85371, 174.76814],
  [-36.85385, 174.76818],
  [-36.85395, 174.76820],
  [-36.85408, 174.76816],
  [-36.85464, 174.76765],
  [-36.85482, 174.76749],
  [-36.85691, 174.76519],
  [-36.85719, 174.76488],
  [-36.85789, 174.76401],
  [-36.85799, 174.76390],
  [-36.85811, 174.76378],
  [-36.86157, 174.76177],
  [-36.86180, 174.76163],
  [-36.86209, 174.76151],
  [-36.86390, 174.76099]
];

const khyberPassRoad = [
  [-36.86390, 174.76099],
  [-36.86396, 174.76102],
  [-36.86402, 174.76107],
  [-36.86408, 174.76115],
  [-36.86639, 174.77217],
  [-36.86760, 174.77795],
  [-36.86766, 174.77803],
  [-36.86771, 174.77806],
  [-36.86779, 174.77808]
];

const khyberPassRoadJunction = [
  [-36.86408, 174.76115],
  [-36.86409, 174.76105],
  [-36.86412, 174.76095],
  [-36.86415, 174.76089]
];

const remueraRoad = [
  [-36.86779, 174.77808],
  [-36.86799, 174.77805],
  [-36.86813, 174.77802],
  [-36.86829, 174.77802],
  [-36.86842, 174.77800],
  [-36.86976, 174.77762],
  [-36.86983, 174.77764],
  [-36.86989, 174.77766],
  [-36.86995, 174.77770],
  [-36.87251, 174.78067],
  [-36.87265, 174.78086],
  [-36.87391, 174.78299],
  [-36.87515, 174.78502],
  [-36.87525, 174.78524],
  [-36.87578, 174.78648],
  [-36.87588, 174.78671],
  [-36.87683, 174.78855],
  [-36.87694, 174.78871],
  [-36.87712, 174.78890],
  [-36.87723, 174.78898],
  [-36.87741, 174.78908],
  [-36.87805, 174.78930],
  [-36.87816, 174.78935],
  [-36.87824, 174.78941],
  [-36.87879, 174.78988],
  [-36.87887, 174.79000],
  [-36.87893, 174.79011],
  [-36.88032, 174.79451],
  [-36.88039, 174.79478],
  [-36.88044, 174.79514],
  [-36.88071, 174.79795],
  [-36.88086, 174.79965]
];

const greenLaneRoad = [
  [-36.88086, 174.79965],
  [-36.88101, 174.80115],
  [-36.88104, 174.80155],
  [-36.88170, 174.80608],
  [-36.88176, 174.80635],
  [-36.88182, 174.80655],
  [-36.88231, 174.80791]
];

const uplandRoad = [
  [-36.88231, 174.80791],
  [-36.88238, 174.80821],
  [-36.88238, 174.80845],
  [-36.88236, 174.80873],
  [-36.88227, 174.80902],
  [-36.88214, 174.80924],
  [-36.88085, 174.81111],
  [-36.88047, 174.81171]
];

const meadowbank = [
  [-36.88047, 174.81171],
  [-36.88029, 174.81208],
  [-36.88022, 174.81239],
  [-36.88020, 174.81260],
  [-36.88022, 174.81283],
  [-36.88046, 174.81444],
  [-36.88048, 174.81466],
  [-36.88046, 174.81484],
  [-36.87945, 174.81827],
  [-36.87941, 174.81852],
  [-36.87941, 174.81870],
  [-36.87962, 174.82084],
  [-36.87963, 174.82108],
  [-36.87958, 174.82137],
  [-36.87952, 174.82159],
  [-36.87943, 174.82180],
  [-36.87928, 174.82206],
  [-36.87830, 174.82333],
  [-36.87756, 174.82431],
  [-36.87713, 174.82489],
  [-36.87699, 174.82513],
  [-36.87638, 174.82621],
  [-36.87604, 174.82686]
];

const victoriaAvenue = [
  [-36.88071, 174.79795],
  [-36.88069, 174.79806],
  [-36.88065, 174.79813],
  [-36.88061, 174.79818],
  [-36.87723, 174.79850],
  [-36.87709, 174.79850],
  [-36.87696, 174.79846],
  [-36.87647, 174.79827],
  [-36.87628, 174.79822],
  [-36.87616, 174.79823],
  [-36.87557, 174.79833],
  [-36.87541, 174.79834],
  [-36.87526, 174.79834],
  [-36.87495, 174.79833],
  [-36.87484, 174.79834],
  [-36.87474, 174.79838],
  [-36.87364, 174.79889],
  [-36.87346, 174.79895],
  [-36.86961, 174.79979],
  [-36.86938, 174.79982],
  [-36.86537, 174.80015],
  [-36.86523, 174.80014],
  [-36.86434, 174.80004]
];

export const line2: Line = {
  name: 'Line 2',
  state: 'NZ',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '1886-02-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1902-11-02',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1956-01-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [khyberPassRoad],
      history: {
        opened: {
          date: '1886-02-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1902-12-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1936-12-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [khyberPassRoadJunction],
      history: {
        opened: {
          date: '1903-05-02',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1936-12-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [remueraRoad],
      history: {
        opened: {
          date: '1886-02-01',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1903-08-24',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1956-08-17',
          status: 'closed'
        }]
      }
    },
    {
      segments: [greenLaneRoad],
      history: {
        opened: {
          date: '1906-08-29',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1956-08-17',
          status: 'closed'
        }]
      }
    },
    {
      segments: [uplandRoad],
      history: {
        opened: {
          date: '1913-05-10',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1956-08-17',
          status: 'closed'
        }]
      }
    },
    {
      segments: [meadowbank],
      history: {
        opened: {
          date: '1930-06-08',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1956-08-17',
          status: 'closed'
        }]
      }
    },
    {
      segments: [victoriaAvenue],
      history: {
        opened: {
          date: '1913-06-09',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1955-04-01',
          status: 'closed'
        }]
      }
    }
  ]
}
