import { Line } from "../../../../trackTypes";

const innerLoopLeft = [
  [-34.92268, 138.60557],
  [-34.92406, 138.60566]
];

const innerLoop = [
  [-34.92406, 138.60566],
  [-34.92436, 138.59990],
  [-34.92431, 138.59977],
  [-34.92425, 138.59969],
  [-34.92415, 138.59962]
];

const grenfelStSouthJunction = [
  [-34.92436, 138.59990],
  [-34.92444, 138.59976],
  [-34.92451, 138.59970],
  [-34.92459, 138.59965]
];

const innerLoopRight = [
  [-34.92437, 138.59964],
  [-34.92425, 138.59963],
  [-34.92298, 138.59952]
];

const cbd = [
  [-34.92298, 138.59952],
  [-34.92268, 138.60557],
  [-34.92241, 138.61085],
  [-34.92222, 138.61453],
  [-34.92214, 138.61502],
  [-34.92199, 138.61543]
];

const rundleStreetWest = [
  [-34.92199, 138.61543],
  [-34.92034, 138.61867]
];

const rundleStreetWestContinued = [
  [-34.92034, 138.61867],
  [-34.91839, 138.62247]
];

const sydenhamRoad = [
  [-34.91839, 138.62247],
  [-34.91835, 138.62268],
  [-34.91840, 138.62284],
  [-34.91853, 138.62300],
  [-34.91861, 138.62307],
  [-34.91867, 138.62323],
  [-34.91849, 138.62714],
  [-34.91853, 138.62724],
  [-34.91859, 138.62731],
  [-34.91868, 138.62737],
  [-34.92137, 138.62756],
  [-34.92147, 138.62763],
  [-34.92151, 138.62771],
  [-34.92154, 138.62780]
];

const kensingtonLoopLeft = [
  [-34.92088, 138.64347],
  [-34.92091, 138.64365],
  [-34.92097, 138.64381],
  [-34.92441, 138.64987],
  [-34.92448, 138.64996],
  [-34.92458, 138.65004],
  [-34.92638, 138.65018],
  [-34.92642, 138.65016],
  [-34.92646, 138.65011],
  [-34.92647, 138.65006]
];

const kensingtonRoad = [
  [-34.92647, 138.65006],
  [-34.92650, 138.64957]
];

const kensingtonLoopRight = [
  [-34.92650, 138.64957],
  [-34.92647, 138.64947],
  [-34.92638, 138.64934],
  [-34.92172, 138.64118],
  [-34.92164, 138.64110],
  [-34.92148, 138.64104],
  [-34.92110, 138.64099],
  [-34.92101, 138.64092],
  [-34.92096, 138.64083],
  [-34.92092, 138.64074]
];

const kensingtonLoopStart = [
  [-34.92154, 138.62780],
  [-34.92092, 138.64074],
  [-34.92095, 138.64095],
  [-34.92099, 138.64123],
  [-34.92088, 138.64347]
];

const kensington = [
  [-34.92088, 138.64347],
  [-34.92059, 138.64963]
];

const outterLoop = [
  [-34.92381, 138.61075],
  [-34.92406, 138.60566]
];

const eastTerrace = [
  [-34.92241, 138.61085],
  [-34.92363, 138.61095],
  [-34.92369, 138.61093],
  [-34.92375, 138.61088],
  [-34.92381, 138.61075]
];

const pulteneyStreetExtension = [
  [-34.92268, 138.60557],
  [-34.92142, 138.60547],
  [-34.92135, 138.60542],
  [-34.92130, 138.60534],
  [-34.92127, 138.60520]
];

const pulteneyStreetEastJunction = [
  [-34.92142, 138.60547],
  [-34.92134, 138.60552],
  [-34.92128, 138.60558],
  [-34.92124, 138.60567]
];

const obahnRoad = [
  [-34.92381, 138.61075],
  [-34.92380, 138.61096],
  [-34.92374, 138.61144],
  [-34.92363, 138.61183],
  [-34.92303, 138.61339],
  [-34.92199, 138.61543]
];

const theParadeWest = [
  [-34.92034, 138.61867],
  [-34.92031, 138.61885],
  [-34.92035, 138.61895],
  [-34.92179, 138.62097],
  [-34.92185, 138.62112],
  [-34.92187, 138.62132],
  [-34.92154, 138.62780]
];

const kensingtonGardens = [
  [-34.92059, 138.64963],
  [-34.91979, 138.66411]
];

export const east1: Line = {
  name: 'East1',
  state: 'SA',
  segments: [
    {
      segments: [innerLoopLeft],
      history: {
        opened: {
          date: '1878-06-10',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-03-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [innerLoop, grenfelStSouthJunction],
      history: {
        opened: {
          date: '1878-06-10',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-03-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1957-12-07',
          status: 'closed'
        }]
      }
    },
    {
      segments: [innerLoopRight],
      history: {
        opened: {
          date: '1878-06-10',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-03-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1958-11-22',
          status: 'closed'
        }, {
          date: '2007-10-14',
          status: 'open'
        }]
      }
    },
    {
      segments: [rundleStreetWestContinued],
      history: {
        opened: {
          date: '1878-06-10',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-05-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kensingtonRoad],
      history: {
        opened: {
          date: '1878-06-10',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1911-09-03',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1952-05-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [cbd, sydenhamRoad, kensingtonLoopLeft, kensingtonLoopRight],
      history: {
        opened: {
          date: '1878-06-10',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-03-09',
          status: 'closed'
        }]
      }
    },
    {
      segments: [rundleStreetWest, kensingtonLoopStart],
      history: {
        opened: {
          date: '1878-06-10',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-03-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1957-02-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [outterLoop],
      history: {
        opened: {
          date: '1881-12-14',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-03-09',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1957-02-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [eastTerrace],
      history: {
        opened: {
          date: '1881-12-14',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram' //horse
        },
        trackChange: [{
          date: '1909-03-09',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pulteneyStreetExtension, pulteneyStreetEastJunction],
      history: {
        opened: {
          date: '1909-03-09',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-07-19',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kensington],
      history: {
        opened: {
          date: '1909-03-09',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1957-02-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [obahnRoad, theParadeWest],
      history: {
        opened: {
          date: '1909-03-09',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1957-02-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [kensingtonGardens],
      history: {
        opened: {
          date: '1911-07-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1957-02-02',
          status: 'closed'
        }]
      }
    }
  ]
};
