import { Line } from "../../../../trackTypes";

const zoo = [
  [-36.86463, 174.73744],
  [-36.86373, 174.73728],
  [-36.86361, 174.73722],
  [-36.86352, 174.73716],
  [-36.86344, 174.73706],
  [-36.86231, 174.73515],
  [-36.86225, 174.73501],
  [-36.86221, 174.73485],
  [-36.86208, 174.73309],
  [-36.86209, 174.73294],
  [-36.86212, 174.73279],
  [-36.86343, 174.73007],
  [-36.86347, 174.72994],
  [-36.86347, 174.72983],
  [-36.86345, 174.72970],
  [-36.86223, 174.72607],
  [-36.86215, 174.72590],
  [-36.86171, 174.72503],
  [-36.86017, 174.72207]
];

const westmere = [
  [-36.86171, 174.72503],
  [-36.86164, 174.72494],
  [-36.86154, 174.72487],
  [-36.86087, 174.72452],
  [-36.85835, 174.72311],
  [-36.85823, 174.72302],
  [-36.85814, 174.72293],
  [-36.85574, 174.72018],
  [-36.85387, 174.71806]
];

export const line8: Line = {
  name: 'Line 8',
  state: 'NZ',
  segments: [
    {
      segments: [zoo],
      history: {
        opened: {
          date: '1923-12-17',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-03-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [westmere],
      history: {
        opened: {
          date: '1931-05-10',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-03-13',
          status: 'closed'
        }]
      }
    }
  ]
}
