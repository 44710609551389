import { Line } from '../../trackTypes';

const line = [
  [-38.17336, 144.39566],
  [-38.17328, 144.39511],
  [-38.17320, 144.39493],
  [-38.17281, 144.39458],
  [-38.17273, 144.39446],
  [-38.17266, 144.39430],
  [-38.17201, 144.39134],
  [-38.17198, 144.39129],
  [-38.17195, 144.39126],
  [-38.17190, 144.39125],
  [-38.15908, 144.39364],
  [-38.15903, 144.39372],
  [-38.15900, 144.39380],
  [-38.15900, 144.39394],
  [-38.16445, 144.41702],
  [-38.16457, 144.41740],
  [-38.16478, 144.41780],
  [-38.16485, 144.41795],
  [-38.16497, 144.41850],
  [-38.16504, 144.41917],
  [-38.16506, 144.42084],
  [-38.16499, 144.42233],
  [-38.16490, 144.42279],
  [-38.16414, 144.42538],
  [-38.16400, 144.42572],
  [-38.16385, 144.42593],
  [-38.16250, 144.42763],
  [-38.16222, 144.42788],
  [-38.16156, 144.42847],
  [-38.16045, 144.42920],
  [-38.15725, 144.43065],
  [-38.15684, 144.43077],
  [-38.15072, 144.43189],
  [-38.15062, 144.43184],
  [-38.15057, 144.43171],
  [-38.15030, 144.42951]
];

const branch = [
  [-38.16445, 144.41702],
  [-38.16450, 144.41754],
  [-38.16451, 144.41855],
  [-38.16424, 144.42060],
  [-38.16415, 144.42084],
  [-38.16399, 144.42105],
  [-38.16384, 144.42114],
  [-38.16370, 144.42121],
  [-38.16346, 144.42123],
  [-38.16298, 144.42141],
  [-38.16287, 144.42150],
  [-38.16277, 144.42162],
  [-38.16169, 144.42403],
  [-38.16156, 144.42425],
  [-38.16100, 144.42509],
  [-38.16002, 144.42608],
  [-38.15974, 144.42626],
  [-38.15498, 144.42813],
  [-38.15458, 144.42822],
  [-38.14732, 144.42954],
  [-38.14721, 144.42951],
  [-38.14717, 144.42943],
  [-38.14703, 144.42836]
];

export const cheethamSaltworksGeelong: Line = {
  name: 'Cheetham Saltworks - Geelong',
  state: 'VIC',
  segments: [
    {
      segments: [line, branch],
      history: {
        opened: {
          date: '1909-12-17',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        },
        trackChange: [{
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    }
  ]
};
