import { Line } from "../../../../trackTypes";

const wadestown = [
  [-41.27178, 174.77771],
  [-41.27101, 174.77656],
  [-41.27095, 174.77650],
  [-41.27090, 174.77649],
  [-41.27084, 174.77650],
  [-41.27078, 174.77653],
  [-41.26949, 174.77803],
  [-41.26889, 174.77870],
  [-41.26876, 174.77880],
  [-41.26812, 174.77919],
  [-41.26799, 174.77921],
  [-41.26789, 174.77919],
  [-41.26750, 174.77905],
  [-41.26741, 174.77906],
  [-41.26734, 174.77911],
  [-41.26731, 174.77916],
  [-41.26729, 174.77924],
  [-41.26727, 174.77951],
  [-41.26724, 174.77963],
  [-41.26717, 174.77974],
  [-41.26701, 174.77991],
  [-41.26695, 174.77999],
  [-41.26692, 174.78007],
  [-41.26685, 174.78036],
  [-41.26681, 174.78043],
  [-41.26674, 174.78049],
  [-41.26667, 174.78050],
  [-41.26660, 174.78048],
  [-41.26654, 174.78042],
  [-41.26634, 174.78017],
  [-41.26625, 174.78008],
  [-41.26610, 174.77998],
  [-41.26585, 174.77987],
  [-41.26577, 174.77985],
  [-41.26568, 174.77986],
  [-41.26561, 174.77993],
  [-41.26556, 174.78000],
  [-41.26553, 174.78008],
  [-41.26552, 174.78019],
  [-41.26557, 174.78060],
  [-41.26556, 174.78078],
  [-41.26551, 174.78093],
  [-41.26543, 174.78108],
  [-41.26533, 174.78121],
  [-41.26521, 174.78128],
  [-41.26507, 174.78131],
  [-41.26495, 174.78131],
  [-41.26484, 174.78125],
  [-41.26471, 174.78115],
  [-41.26464, 174.78104],
  [-41.26459, 174.78090],
  [-41.26404, 174.77830],
  [-41.26396, 174.77813],
  [-41.26388, 174.77803],
  [-41.26377, 174.77795],
  [-41.26290, 174.77759],
  [-41.26276, 174.77749],
  [-41.26268, 174.77741],
  [-41.26263, 174.77730],
  [-41.26259, 174.77719],
  [-41.26254, 174.77686],
  [-41.26249, 174.77671],
  [-41.26239, 174.77654],
  [-41.26226, 174.77640],
  [-41.26180, 174.77599],
  [-41.26172, 174.77587],
  [-41.26167, 174.77575],
  [-41.26164, 174.77555],
  [-41.26165, 174.77544],
  [-41.26166, 174.77529],
  [-41.26189, 174.77459]
];

export const line7: Line = {
  name: 'Line 7',
  state: 'NZ',
  segments: [
    {
      segments: [wadestown],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1949-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
