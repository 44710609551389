import { Line } from '../trackTypes';
import { kew } from './victoria/kew';
import { flemingtonRacecource } from './victoria/flemington';
import { maryborough } from './victoria/maryborough';
import { whittlesea } from './victoria/whittlesea';
import { warrnambool } from './victoria/warrnambool';
import { avoca } from './victoria/crossCountry/avoca'
import { seymour } from './victoria/seymour'
import { bendigo } from './victoria/bendigo';
import { sandringham } from './victoria/sandringham';
import { frankston } from './victoria/frankston';
import { upfield } from './victoria/upfield';
import { robinvale } from './victoria/robinvale'
import { hurstbridge } from './victoria/hurstbridge';
import { ballarat } from './victoria/ballarat';
import { geelongFreight } from './victoria/geelongFreight';
import { piangil } from './victoria/piangil';
import { craigieburn } from './victoria/craigeburn';
import { glenwaverley } from './victoria/glenwaverley';
import { cityLoop } from './victoria/cityLoop';
import { fitzroy } from './victoria/fitzroy';
import { innerCircle } from './victoria/innerCircle';
import { pakenham } from './victoria/pakenham';
import { lilydale } from './victoria/lilydale';
import { echuca } from './victoria/echuca';
import { stonyPoint } from './victoria/morningtonPeninsula/stonyPoint';
import { sunbury } from './victoria/sunbury';
import { cranbourne } from './victoria/cranbourne';
import { westernSG } from './victoria/westernSG';
import { daylesford } from './victoria/crossCountry/daylesford';
import { geelongBallarat } from './victoria/crossCountry/geelongBallarat';
import { queenscliff } from './victoria/queenscliff';
import { RRL } from './victoria/RRL';
import { werribee } from './victoria/werribee';
import { portland } from './victoria/portland/portland';
import { tocumwal } from './victoria/tocumwal/tocumwal';
import { ararat } from './victoria/ararat';
import { healesville } from './victoria/healsville';
import { alamein } from './victoria/alamein';
import { mildura } from './victoria/mildura';
import { hopetoun } from './victoria/hopetoun';
import { eaglehawkInglewood } from './victoria/crossCountry/eaglehawkInglewood';
import { panitya } from './victoria/panitya';
import { belgrave } from './victoria/belgrave';
import { williamstown } from './victoria/williamstown';
import { toolamba } from './victoria/crossCountry/toolamba';
import { maffra } from './victoria/laTrobeValley/maffra';
import { mortlake } from './victoria/mortlake';
import { moolort } from './victoria/moolort';
import { maldon } from './victoria/maldon';
import { geelong } from './victoria/geelong';
import { yaapeet } from './victoria/yaapeet';
import { springvaleCemetery } from './victoria/springvaleCemetery';
import { walhalla } from './victoria/narrowGauge/walhalla';
import { northEast } from './victoria/northEast/northEast';
import { kulwin } from './victoria/kulwin';
import { mornington } from './victoria/morningtonPeninsula/mornington';
import { portMelbourne } from './victoria/portMelbourne';
import { southGippsland } from './victoria/southGippsland/southGippsland';
import { gembrook } from './victoria/narrowGauge/gembrook';
import { melbourneFreight } from './victoria/melbourneFreight';
import { oaklands } from './victoria/northEast/oaklands';
import { wahgunyah } from './victoria/northEast/wahgunyah';
import { montPark } from './victoria/montPark';
import { skipton } from './victoria/skipton';
import { easternFreight } from './victoria/easternFreight';
import { castlemaineMaryborough } from './victoria/crossCountry/castlemaineMaryborough';
import { peechelbaEast } from './victoria/northEast/peechelbaEast';
import { redHill } from './victoria/morningtonPeninsula/redHill';
import { wensleydale } from './victoria/wensleydale';
import { thorpdale } from './victoria/laTrobeValley/thorpdale';
import { bolangum } from './victoria/bolangum';
import { outerCircle } from './victoria/outerCircle'
import { cobram } from './victoria/tocumwal/cobram';
import { alvie } from './victoria/alvie';
import { buninyong } from './victoria/buninyong';
import { colacBallarat } from './victoria/crossCountry/colacBallarat';
import { yanac } from './victoria/yanac';
import { stonyCrossing } from './victoria/stonyCrossing';
import { mtGambier } from './victoria/portland/mtGambier';
import { outtrim } from './victoria/southGippsland/outtrim';
import { tatong } from './victoria/northEast/tatong';
import { forrest } from './victoria/forrest';
import { crowes } from './victoria/narrowGauge/crowes';
import { timboon } from './victoria/timboon';
import { heathcote } from './victoria/crossCountry/heathcote';
import { bright } from './victoria/northEast/bright';
import { navarre } from './victoria/navarre';
import { carpolac } from './victoria/carpolac';
import { wonthaggi } from './victoria/southGippsland/wonthaggi';
import { strzelecki } from './victoria/southGippsland/strzelecki';
import { mirbooNorth } from './victoria/laTrobeValley/mirbooNorth';
import { noojee } from './victoria/laTrobeValley/noojee';
import { cohuna } from './victoria/cohuna';
import { picola } from './victoria/tocumwal/picola';
import { dookie } from './victoria/tocumwal/dookie';
import { whitfield } from './victoria/narrowGauge/whitfield';
import { cudgewa } from './victoria/northEast/cudgewa';
import { yackandandah } from './victoria/northEast/yackandandah';
import { koroitHamilton } from './victoria/koroitHamilton';
import { grampians } from './victoria/grampians';
import { stKilda } from './victoria/stKilda';
import { morkalla } from './victoria/morkalla';
import { laTrobeValley } from './victoria/laTrobeValley/laTrobeValley';
import { mansfield } from './victoria/northEast/mansfield';
import { redesdale } from './victoria/redesdale';
import { westernFreight } from './victoria/westernFreight';
import { warburton } from './victoria/warburton';
import { waubra } from './victoria/waubra';
import { colbinabbin } from './victoria/tocumwal/colbinabbin';
import { girgarre } from './victoria/tocumwal/girgarre';
import { wedderburn } from './victoria/wedderburn';
import { casterton } from './victoria/portland/casterton';
import { hamiltonEastNatimuk } from './victoria/crossCountry/hamiltonEastNatimuk';
import { lancefield } from './victoria/crossCountry/lancefield';
import { coleraine } from './victoria/portland/coleraine';
import { koondrook } from './victoria/koonrook';
import { rosstown } from './victoria/rosstown';
import { mcKenzieCreek } from './victoria/industrialTrams/mcKenzieCreek';
import { millewaSouth } from './victoria/millewaSouth';
import { lalLalRacecourse } from './victoria/lalLalRacecourse';
import { geelongTrams } from './victoria/trams/geelongTrams';
import { portlandTrams } from './victoria/trams/portlandTrams';
import { ballaratTrams } from './victoria/trams/ballaratTrams';
import { bendigoTrams } from './victoria/trams/bendigoTrams';
import { powelltown } from './victoria/industrialTrams/powelltown';
import { sorentoTram } from './victoria/trams/sorentoTram';
import { echucaWharf } from './victoria/echucaWharf';
import { masonMoore } from './victoria/industrialTrams/masonMoore';
import { elphinstone } from './victoria/industrialTrams/elphinstone';
import { metroTunnel } from './victoria/metroTunnel';
import { srl } from './victoria/srl';
import { coalCreek } from './victoria/southGippsland/coalCreek';
import { yallourn } from './victoria/yallourn';
import { tyersValley } from './victoria/tyersValley';
import { mcIvor } from './victoria/mcIvor';
import { fyansford } from './victoria/fyansford';
import { melbourneTrams } from './victoria/trams/melbourneTrams';
import { lorneTram } from './victoria/lorneTram';
import { goodwoodTimberTram } from './victoria/goodwoodTimberTram';
import { redcliffsTramway } from './victoria/redcliffsTramway';
import { cheethamSaltworksGeelong } from './victoria/cheethamSaltworksGeelong';

export const vicLines: Line[] = [
  geelongBallarat,
  daylesford,
  sunbury,
  healesville,
  stonyPoint,
  westernSG,
  RRL,
  queenscliff,
  warrnambool,
  geelongFreight,
  cityLoop,
  whittlesea,
  kew,
  flemingtonRacecource,
  glenwaverley,
  cranbourne,
  ararat,
  seymour,
  upfield,
  frankston,
  tocumwal,
  avoca,
  echuca,
  werribee,
  ballarat,
  bendigo,
  sandringham,
  robinvale,
  hurstbridge,
  lilydale,
  pakenham,
  maryborough,
  piangil,
  craigieburn,
  fitzroy,
  innerCircle,
  portland,
  alamein,
  mildura,
  hopetoun,
  eaglehawkInglewood,
  panitya,
  belgrave,
  williamstown,
  toolamba,
  maffra,
  mortlake,
  moolort,
  maldon,
  geelong,
  yaapeet,
  springvaleCemetery,
  walhalla,
  northEast,
  kulwin,
  mornington,
  portMelbourne,
  southGippsland,
  gembrook,
  melbourneFreight,
  oaklands,
  wahgunyah,
  montPark,
  skipton,
  easternFreight,
  castlemaineMaryborough,
  peechelbaEast,
  redHill,
  wensleydale,
  thorpdale,
  bolangum,
  outerCircle,
  cobram,
  alvie,
  buninyong,
  colacBallarat,
  yanac,
  stonyCrossing,
  mtGambier,
  outtrim,
  tatong,
  forrest,
  crowes,
  timboon,
  heathcote,
  bright,
  navarre,
  carpolac,
  wonthaggi,
  strzelecki,
  mirbooNorth,
  noojee,
  cohuna,
  picola,
  dookie,
  whitfield,
  cudgewa,
  yackandandah,
  koroitHamilton,
  grampians,
  stKilda,
  morkalla,
  laTrobeValley,
  mansfield,
  redesdale,
  westernFreight,
  warburton,
  waubra,
  colbinabbin,
  girgarre,
  wedderburn,
  casterton,
  hamiltonEastNatimuk,
  lancefield,
  coleraine,
  millewaSouth,
  koondrook,
  rosstown,
  geelongTrams,
  portlandTrams,
  ballaratTrams,
  bendigoTrams,
  mcKenzieCreek,
  lalLalRacecourse,
  powelltown,
  sorentoTram,
  echucaWharf,
  masonMoore,
  elphinstone,
  metroTunnel,
  srl,
  coalCreek,
  yallourn,
  tyersValley,
  mcIvor,
  fyansford,
  lorneTram,
  goodwoodTimberTram,
  redcliffsTramway,
  cheethamSaltworksGeelong,
  ...melbourneTrams
];
