import { Line } from "../../trackTypes";

const albionJacana = [
  [-37.7753, 144.8230],
  [-37.7741, 144.8226],
  [-37.7734, 144.8226],
  [-37.7719, 144.8231],
  [-37.7713, 144.8234],
  [-37.7708, 144.8239],
  [-37.7663, 144.8286],
  [-37.7470, 144.8464],
  [-37.7323, 144.8600],
  [-37.7223, 144.8692],
  [-37.7212, 144.8704],
  [-37.7206, 144.8712],
  [-37.7117, 144.8865],
  [-37.7107, 144.8887],
  [-37.7098, 144.8919],
  [-37.7089, 144.9013],
  [-37.7085, 144.9032],
  [-37.7076, 144.9049],
  [-37.7066, 144.9061],
  [-37.6966, 144.9146],
  [-37.6957, 144.9152],
  [-37.6934, 144.9159]
];

const newportSunshine = [
  [-37.8386, 144.8838],
  [-37.8373, 144.8837],
  [-37.8365, 144.8834],
  [-37.8358, 144.8829],
  [-37.8350, 144.8820],
  [-37.8346, 144.8815],
  [-37.8340, 144.8803],
  [-37.8323, 144.8780],
  [-37.8314, 144.8770],
  [-37.8206, 144.8620],
  [-37.8154, 144.8564],
  [-37.8033, 144.8441]
];

const tottenhamBrooklyn = [
  [-37.8033, 144.8441],
  [-37.8023, 144.8437],
  [-37.8015, 144.8438],
  [-37.8008, 144.8440],
  [-37.8002, 144.8444],
  [-37.7991, 144.8455],
  [-37.7985, 144.8461],
  [-37.7980, 144.8467],
  [-37.7977, 144.8474],
  [-37.7975, 144.8480]
];

const tottenhamBrooklynWest = [
  [-37.7991, 144.8455],
  [-37.7984, 144.8458],
  [-37.7980, 144.8457],
  [-37.7976, 144.8456],
  [-37.7969, 144.8449]
];

const newportSunshineBroadGauge = [
  [-37.8033, 144.8441],
  [-37.7913, 144.8350]
];

const westFootscrayBunburyTunnel = [
  [-37.8025, 144.8988],
  [-37.8022, 144.8997],
  [-37.8021, 144.9005],
  [-37.8022, 144.9014],
  [-37.8023, 144.9021]
];

const bunburyTunnel = [
  [-37.8023, 144.9021],
  [-37.8036, 144.9066]
];

const bunburyTunnelSouthKensington = [
  [-37.8036, 144.9066],
  [-37.8046, 144.9101],
  [-37.8047, 144.9117],
  [-37.8046, 144.9124],
  [-37.8043, 144.9130],
  [-37.8027, 144.9151],
  [-37.7995, 144.9206],
  [-37.7993, 144.9213],
  [-37.7991, 144.9228]
];

const mooneeePondsCreek = [
  [-37.8089, 144.9434],
  [-37.8065, 144.9387],
  [-37.8060, 144.9369],
  [-37.8054, 144.9351],
  [-37.8048, 144.9338],
  [-37.8044, 144.9330],
  [-37.8042, 144.9321],
  [-37.8028, 144.9199],
  [-37.8029, 144.9192],
  [-37.8031, 144.9187],
  [-37.8041, 144.9173],
  [-37.8044, 144.9166]
];

const longIsland = [
  [-38.2827, 145.1882],
  [-38.2837, 145.1882],
  [-38.2843, 145.1884],
  [-38.2849, 145.1889],
  [-38.2853, 145.1895],
  [-38.2856, 145.1902],
  [-38.2863, 145.1932],
  [-38.2884, 145.2021],
  [-38.2892, 145.2053],
  [-38.2896, 145.2061],
  [-38.2900, 145.2066],
  [-38.2902, 145.2071],
  [-38.2921, 145.2149],
  [-38.2924, 145.2155],
  [-38.2926, 145.2158],
  [-38.2940, 145.2173],
  [-38.2947, 145.2176],
  [-38.2954, 145.2176]
];

const longIsland2 = [
  [-38.2884, 145.2021],
  [-38.2888, 145.2045],
  [-38.2887, 145.2053],
  [-38.2885, 145.2060],
  [-38.2883, 145.2067],
  [-38.2882, 145.2075],
  [-38.2904, 145.2165]
];

const longIsland3 = [
  [-38.2863, 145.1932],
  [-38.2866, 145.1953],
  [-38.2865, 145.1963],
  [-38.2861, 145.1976],
  [-38.2854, 145.1984],
  [-38.2846, 145.1989],
  [-38.2840, 145.1991],
  [-38.2827, 145.1989],
  [-38.2815, 145.1987],
  [-38.2807, 145.1988],
  [-38.2802, 145.1993],
  [-38.2799, 145.1999],
  [-38.2799, 145.2010],
  [-38.2801, 145.2023],
  [-38.2813, 145.2068]
];

const boralResourcesSiding = [
  [-37.7670, 144.7378],
  [-37.7668, 144.7369],
  [-37.7669, 144.7360],
  [-37.7672, 144.7352],
  [-37.7678, 144.7346],
  [-37.7788, 144.7298],
  [-37.7792, 144.7295]
];

const webbDock = [
  [-37.8085, 144.9431],
  [-37.8116, 144.9444],
  [-37.8178, 144.9472],
  [-37.8182, 144.9471],
  [-37.8185, 144.9466],
  [-37.8200, 144.9464],
  [-37.8232, 144.9472],
  [-37.8237, 144.9472],
  [-37.8243, 144.9470],
  [-37.8245, 144.9464],
  [-37.8242, 144.9437],
  [-37.8238, 144.9421],
  [-37.8210, 144.9283],
  [-37.8206, 144.9245],
  [-37.8207, 144.9198],
  [-37.8214, 144.9143],
  [-37.8222, 144.9116],
  [-37.8226, 144.9111],
  [-37.8231, 144.9108],
  [-37.8238, 144.9108],
  [-37.8254, 144.9113],
  [-37.8261, 144.9114],
  [-37.8266, 144.9112],
  [-37.8270, 144.9107],
  [-37.8275, 144.9095],
  [-37.8293, 144.9068],
  [-37.8301, 144.9059],
  [-37.8319, 144.9046],
  [-37.8327, 144.9044],
  [-37.8336, 144.9045],
  [-37.8345, 144.9053],
  [-37.8347, 144.9058],
  [-37.8348, 144.9068],
  [-37.8350, 144.9074],
  [-37.8353, 144.9099],
  [-37.8356, 144.9107],
  [-37.8360, 144.9110],
  [-37.8364, 144.9112],
  [-37.8394, 144.9117],
  [-37.8409, 144.9119],
  [-37.8411, 144.9119],
  [-37.8420, 144.9111],
  [-37.8427, 144.9107],
  [-37.8436, 144.9109],
  [-37.8478, 144.9142]
];

const navalBase = [
  [-38.3646, 145.2013],
  [-38.3639, 145.1986],
  [-38.3632, 145.1960],
  [-38.3631, 145.1945],
  [-38.3635, 145.1933],
  [-38.3641, 145.1925],
  [-38.3651, 145.1922],
  [-38.3700, 145.1920]
];

const navalBaseExit2 = [
  [-38.3631, 145.1945],
  [-38.3622, 145.1958],
  [-38.3616, 145.1962],
  [-38.3608, 145.1963],
  [-38.3601, 145.1962]
];

const secSiding = [
  [-37.8473, 144.8528],
  [-37.8470, 144.8539],
  [-37.8461, 144.8550],
  [-37.8415, 144.8558]
];

const newportPowerStation = [
  [-37.8436, 144.8929],
  [-37.8438, 144.8929],
  [-37.8439, 144.8931],
  [-37.8442, 144.8957],
  [-37.8443, 144.8959],
  [-37.8451, 144.8958],
  [-37.8452, 144.8954],
  [-37.8449, 144.8927],
  [-37.8448, 144.8926],
  [-37.8436, 144.8929],
  [-37.8400, 144.8932],
  [-37.8389, 144.8929],
  [-37.8384, 144.8926],
  [-37.8381, 144.8919],
  [-37.8375, 144.8889],
  [-37.8371, 144.8864],
  [-37.8367, 144.8856],
  [-37.8362, 144.8851],
  [-37.8354, 144.8846]
];

const appletonDockNew = [
  [-37.8060, 144.9278],
  [-37.8064, 144.9286],
  [-37.8069, 144.9290],
  [-37.8075, 144.9292],
  [-37.8082, 144.9291],
  [-37.8089, 144.9293],
  [-37.8096, 144.9300],
  [-37.8095, 144.9299],
  [-37.8103, 144.9307],
  [-37.8110, 144.9310],
  [-37.8116, 144.9309],
  [-37.8122, 144.9303],
  [-37.8125, 144.9300],
  [-37.8178, 144.9160]
];

const swanstonDockNew = [
  [-37.8075, 144.9292],
  [-37.8085, 144.9288],
  [-37.8090, 144.9283],
  [-37.8092, 144.9274],
  [-37.8092, 144.9269],
  [-37.8089, 144.9256],
  [-37.8073, 144.9094]
];

const victoriaDock = [
  [-37.8095, 144.9299],
  [-37.8100, 144.9311],
  [-37.8112, 144.9324],
  [-37.8119, 144.9328],
  [-37.8127, 144.9328],
  [-37.8164, 144.9317],
  [-37.8173, 144.9314]
];

const appletonDockOriginal = [

];

const swanstonDockEast = [
//o1972-08-17
//c1997-01-01
];

const swanstonDockWest = [

];

const dynon = [
  [-37.8047, 144.9117],
  [-37.8048, 144.9125],
  [-37.8044, 144.9166],
  [-37.8044, 144.9179],
  [-37.8048, 144.9198],
  [-37.8053, 144.9250],
  [-37.8054, 144.9259],
  [-37.8060, 144.9278],
];

const maribyrnongRiverLine = [
  [-37.7980, 144.9127],
  [-37.7983, 144.9118],
  [-37.7988, 144.9110],
  [-37.7993, 144.9105],
  [-37.8012, 144.9094],
  [-37.8062, 144.9069],
  [-37.8092, 144.9054],
  [-37.8105, 144.9049],
  [-37.8112, 144.9042],
  [-37.8117, 144.9034],
  [-37.8142, 144.9003]
];

const boxHillBrickworksSiding = [
  [-37.8196, 145.1243],
  [-37.8198, 145.1219],
  [-37.8198, 145.1198],
  [-37.8201, 145.1195],
  [-37.8220, 145.1191],
  [-37.8226, 145.1186],
  [-37.8237, 145.1164]
];

const hoffmanBrickworks = [
  [-37.7711, 144.9592],
  [-37.7706, 144.9590],
  [-37.7702, 144.9586],
  [-37.7700, 144.9581],
  [-37.7694, 144.9533],
  [-37.7694, 144.9529],
  [-37.7696, 144.9518]
];

const fordSidings = [
  [-37.6559, 144.9428],
  [-37.6564, 144.9431],
  [-37.6567, 144.9435],
  [-37.6571, 144.9437],
  [-37.6614, 144.9456],
  [-37.6619, 144.9461],
  [-37.6622, 144.9465],
  [-37.6623, 144.9473],
  [-37.6623, 144.9482]
];

const brightSteelsSiding = [
  [-37.6345, 144.9369],
  [-37.6356, 144.9369],
  [-37.6362, 144.9371],
  [-37.6367, 144.9377],
  [-37.6370, 144.9386],
  [-37.6373, 144.9409]
];

const somertonIntermodal = [
  [-37.6281, 144.9377],
  [-37.6287, 144.9380],
  [-37.6292, 144.9385],
  [-37.6294, 144.9392],
  [-37.6295, 144.9398],
  [-37.6293, 144.9412],
  [-37.6292, 144.9417],
  [-37.6289, 144.9421],
  [-37.6288, 144.9425],
  [-37.6287, 144.9430],
  [-37.6292, 144.9473],
  [-37.6294, 144.9479],
  [-37.6298, 144.9484],
  [-37.6303, 144.9487],
  [-37.6312, 144.9487],
  [-37.6319, 144.9488]
];

const somertonIntermodalTwo = [
  [-37.6295, 144.9398],
  [-37.6304, 144.9484]
];

const stewartLloydsSiding = [
  [-37.6242, 144.9386],
  [-37.6235, 144.9390],
  [-37.6231, 144.9398],
  [-37.6230, 144.9406],
  [-37.6235, 144.9444]
];

const blueCircleSouthernCementSiding = [
  [-37.6207, 144.9390],
  [-37.6203, 144.9392],
  [-37.6194, 144.9399],
  [-37.6190, 144.9407],
  [-37.6189, 144.9417],
  [-37.6195, 144.9460]
];

const clydeIndustriesSiding = [
  [-37.6435, 144.9352],
  [-37.6440, 144.9357],
  [-37.6443, 144.9364],
  [-37.6449, 144.9400]
];

const sidingA = [
  [-37.6412, 144.9349],
  [-37.6396, 144.9358],
  [-37.6366, 144.9364],
  [-37.6345, 144.9369],
  [-37.6297, 144.9379],
  [-37.6291, 144.9378],
  [-37.6286, 144.9377],
  [-37.6281, 144.9377]
];

export const melbourneFreight: Line = {
  name: 'Melbourne Freight',
  state: 'VIC',
  segments: [
    {
      segments: [albionJacana],
      history: {
        opened: {
          date: '1929-07-01',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1962-01-03',
          tracks: 1,
          parallelStandard: true
        }]
      }
    },
    {
      segments: [newportSunshine],
      history: {
        opened: {
          date: '1887-09-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1967-07-22',
          tracks: 2
        }, {
          date: '1995-02-15',
          gauge: 'dual',
        }]
      }
    },
    {
      segments: [newportSunshineBroadGauge],
      history: {
        opened: {
          date: '1887-09-24',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [tottenhamBrooklyn],
      history: {
        opened: {
          date: '1965-02-15',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1995-02-15',
          gauge: 'dual',
        }]
      }
    },
    {
      segments: [tottenhamBrooklynWest],
      history: {
        opened: {
          date: '2010-08-03',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [
        westFootscrayBunburyTunnel,
        {
          name: 'Bunbury Street Tunnel',
          segment: bunburyTunnel,
          type: 'tunnel'
        },
        bunburyTunnelSouthKensington
      ],
      history: {
        opened: {
          date: '1928-10-21',
          tracks: 2,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1962-01-03',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [mooneeePondsCreek],
      history: {
        opened: {
          date: '1962-03-16',
          tracks: 1,
          gauge: 'dual',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [longIsland, longIsland2, longIsland3],
      history: {
        opened: {
          date: '1969-03-29',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [boralResourcesSiding],
      history: {
        opened: {
          date: '1968-04-10',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [webbDock],
      history: {
        opened: {
          date: '1986-02-03',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1996-06-14',
          status: 'closed'
        }]
      }
    },
    {
      segments: [navalBase, navalBaseExit2],
      history: {
        opened: {
          date: '1914-07-06',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1981-06-22',
          status: 'closed'
        }]
      }
    },
    {
      segments: [appletonDockOriginal],
      history: {
        opened: {
          date: '1956-11-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1997-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [victoriaDock],
      history: {
        opened: {
          date: '2009-10-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1962-01-01',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [secSiding],
      history: {
        opened: {
          date: '1952-11-09',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1993-09-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [newportPowerStation],
      history: {
        opened: {
          date: '1914-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1954-09-13',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1971-01-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [swanstonDockNew],
      history: {
        opened: {
          date: '2003-02-01',
          tracks: 1,
          gauge: 'dual',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [appletonDockNew],
      history: {
        opened: {
          date: '2003-02-01',
          tracks: 1,
          gauge: 'dual',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [dynon],
      history: {
        opened: {
          date: '1962-01-03',
          tracks: 2,
          gauge: 'dual',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [maribyrnongRiverLine],
      history: {
        opened: {
          date: '1921-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2006-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [boxHillBrickworksSiding],
      history: {
        opened: {
          date: '1922-07-04',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1929-01-29',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1944-09-12',
          status: 'closed'
        }]
      }
    },
    {
      segments: [hoffmanBrickworks],
      history: {
        opened: {
          date: '1886-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1972-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [fordSidings],
      history: {
        opened: {
          date: '1959-07-19',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1963-01-11',
          gauge: 'dual'
        }, {
          date: '1997-05-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [brightSteelsSiding],
      history: {
        opened: {
          date: '1962-08-05',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1988-02-23',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [somertonIntermodal],
      history: {
        opened: {
          date: '2016-12-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2021-01-01',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [somertonIntermodalTwo],
      history: {
        opened: {
          date: '2016-12-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2021-01-01',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [stewartLloydsSiding],
      history: {
        opened: {
          date: '1962-10-04',
          tracks: 1,
          gauge: 'standard',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [blueCircleSouthernCementSiding],
      history: {
        opened: {
          date: '1988-02-23',
          tracks: 1,
          gauge: 'dual',
          trackType: 'heavy'
        }
      }
    },
    {
      segments: [clydeIndustriesSiding],
      history: {
        opened: {
          date: '1889-10-08',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '1903-07-13',
          status: 'closed'
        }, {
          date: '1956-05-05',
          status: 'closed'
        }, {
          date: '1928-03-05',
          status: 'open'
        }, {
          date: '1959-07-19',
          status: 'open'
        }, {
          date: '1963-01-11',
          gauge: 'dual'
        }]
      }
    },
    {
      segments: [sidingA],
      history: {
        opened: {
          date: '1963-01-01',
          tracks: 1,
          gauge: 'broad',
          trackType: 'heavy'
        }
      }
    }
  ]
}

/**
 * swanston dock
 *  built to broad gauge
 *  closed when marval staduim was decided to be built
 *
 * route past victoria dock, opened in 1986
 *
 * appleton dock
 * opened 1956
 * closed 1992
 * reopened 2000s
 *
 * swanston dock
 * 1960s
 * closed 1992
 * opened 2003-02-01, as a long siding
 */
