import { Line } from "../../../trackTypes";

const line = [
  [-31.9186, 115.9195],
  [-31.9186, 115.9215],
  [-31.9179, 115.9233],
  [-31.9157, 115.9242],
  [-31.9132, 115.9237],
  [-31.9023, 115.9232],
  [-31.9001, 115.9227],
  [-31.8977, 115.9214],
  [-31.8948, 115.9191],
  [-31.8930, 115.9182],
  [-31.8907, 115.9179],
  [-31.8855, 115.9187],
  [-31.8824, 115.9189],
  [-31.8788, 115.9186],
  [-31.8725, 115.9174],
  [-31.8695, 115.9171],
  [-31.8559, 115.9171],
  [-31.8529, 115.9172],
  [-31.8513, 115.9178],
  [-31.8503, 115.9192],
  [-31.8478, 115.9283],
  [-31.8476, 115.9321],
  [-31.8513, 115.9531],
  [-31.8513, 115.9614],
  [-31.8504, 115.9641],
  [-31.8487, 115.9658],
  [-31.8467, 115.9662],
  [-31.8418, 115.9661],
  [-31.8377, 115.9652],
  [-31.8298, 115.9625],
  [-31.8273, 115.9619],
  [-31.8178, 115.9619],
  [-31.8082, 115.9609],
  [-31.8055, 115.9600],
  [-31.7996, 115.9576],
  [-31.7982, 115.9574],
  [-31.7971, 115.9576],
  [-31.7954, 115.9581],
  [-31.7929, 115.9580],
  [-31.7903, 115.9573],
  [-31.7884, 115.9575],
  [-31.7850, 115.9593],
  [-31.7810, 115.9635]
];

export const ellenbrook: Line = {
  name: 'Ellenbrook',
  state: 'WA',
  segments: [
    {
      segments: [line],
      history: {
        opened: {
          date: '2024-12-08',
          tracks: 2,
          gauge: 'narrow',
          electrified: {
            current: 'AC',
            isOverhead: true
          },
          trackType: 'heavy'
        }
      }
    }
  ]
};
