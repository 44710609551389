import { Line } from '../trackTypes';
import { manukau } from './northIsland/manukau';
import { onehunga } from './northIsland/onehunga';
import { waiuku } from './northIsland/waiuku';
import { missionBush } from './northIsland/missionBush';
import { waitara } from './northIsland/taranaki/waitara';
import { raetihi } from './northIsland/raetihi';
import { northAuckland } from './northIsland/northAuckland';
import { wairarapa } from './northIsland/wairarapa';
import { opunake } from './northIsland/taranaki/opunake';
import { northIslandMainTrunk } from './northIsland/northIsalndMainTrunk';
import { foxton } from './northIsland/foxton';
import { gracefield } from './northIsland/gracefield';
import { newPlymouthTrams } from './northIsland/trams/newPlymouthTrams';
import { martonNewPlymouth } from './northIsland/martonNewPlymouth';
import { whanganui } from './northIsland/taranaki/whanganui';
import { palmerstonNorthGisborne } from './northIsland/palmerstonNorthGisborne';
import { ahuriri } from './northIsland/hawkesBay/ahuriri';
import { greytown } from './northIsland/greytown';
import { cambridge } from './northIsland/waikato/cambridge';
import { eastCoastMainTrunk } from './northIsland/eastCoastMainTrunk';
import { gisborneTrams } from './northIsland/trams/gisborneTrams';
import { napierTrams } from './northIsland/trams/napierTrams';
import { taneatua } from './northIsland/bayOfPlenty/taneatua';
import { thames } from './northIsland/waikato/thames';
import { kinleith } from './northIsland/bayOfPlenty/kinleith';
import { rotorua } from './northIsland/bayOfPlenty/rotorua';
import { stratfordOkahukura } from './northIsland/stratfordOkahukura';
import { ngatapa } from './northIsland/hawkesBay/ngatapa';
import { glenAfton } from './northIsland/waikato/glenAfton';
import { moutohora } from './northIsland/hawkesBay/moutohora';
import { sulphurPoint } from './northIsland/sulphurPoint';
import { mountMaunganui } from './northIsland/bayOfPlenty/mountMaunganui';
import { whanganuiTrams } from './northIsland/trams/whanganuiTrams';
import { taonui } from './northIsland/taonui';
import { murupara } from './northIsland/murupara';
import { opua } from './northIsland/northland/opua';
import { mountEgmont } from './northIsland/taranaki/mountEgmont';
import { donnellysCrossing } from './northIsland/northland/donnellysCrossing';
import { dargaville } from './northIsland/northland/dargaville';
import { okaihau } from './northIsland/northland/okaihau';
import { teAro } from './northIsland/teAro';
import { onerahi } from './northIsland/northland/onerahi';
import { aucklandTrams } from './northIsland/trams/aucklandTrams';
import { kumeuRiverhead } from './northIsland/northland/kumeuRiverhead';
import { kimihiaColliery } from './northIsland/waikato/kimihiaColliery';
import { whakataneBoardMills } from './northIsland/bayOfPlenty/whakataneBoardMills';
import { glenMassey } from './northIsland/waikato/glenMassey';
import { huttPark } from './northIsland/huttPark';
import { mainSouthLine } from './southIsland/mainSouthLine';
import { bluff } from './southIsland/southland/bluff';
import { rewanui } from './southIsland/westland/rewanui';
import { rapahoe } from './southIsland/westland/rapahoe';
import { ross } from './southIsland/westland/ross';
import { connsCreek } from './southIsland/westland/connsCreek';
import { blackball } from './southIsland/westland/blackball';
import { capeFoulwind } from './southIsland/westland/capeFoulwind';
import { seddonville } from './southIsland/westland/seddonville';
import { stillwaterWestport } from './southIsland/westland/stillwaterWestport';
import { midland } from './southIsland/midland';
import { tuatapere } from './southIsland/southland/tuatapere';
import { wairio } from './southIsland/southland/wairio';
import { kingston } from './southIsland/southland/kingston';
import { mossburn } from './southIsland/southland/mossburn';
import { tokanui } from './southIsland/southland/tokanui';
import { wyndham } from './southIsland/southland/wyndham';
import { hedgehope } from './southIsland/southland/hedgehope';
import { invercargillTrams } from './southIsland/trams/invercargillTrams';
import { waimeaPlains } from './southIsland/southland/waimeaPlains';
import { waikaia } from './southIsland/southland/waikaia';
import { waikaka } from './southIsland/southland/waikaka';
import { catlinsRiver } from './southIsland/otago/catlinsRiver';
import { kaitangata } from './southIsland/otago/kaitangata';
import { tapanui } from './southIsland/otago/tapanui';
import { dunedinTrams } from './southIsland/trams/dunedinTrams';
import { portChalmers } from './southIsland/otago/portChalmers';
import { lyttelton } from './southIsland/canterbury/lyttelton';
import { fairlie } from './southIsland/canterbury/fairlie';
import { southbridge } from './southIsland/canterbury/southbridge';
import { mainNorthLine } from './southIsland/mainNorthLine';
import { roxburgh } from './southIsland/otago/roxburgh';
import { methven } from './southIsland/canterbury/methven';
import { dunbackMakareao } from './southIsland/otago/dunbackMakareao';
import { fernhill } from './southIsland/otago/fernhill';
import { oceanBeach } from './southIsland/otago/oceanBeach';
import { kurow } from './southIsland/otago/kurow';
import { outram } from './southIsland/otago/outram';
import { waltonPark } from './southIsland/otago/waltonPark';
import { shagPoint } from './southIsland/otago/shagPoint';
import { mountSomers } from './southIsland/canterbury/mountSomers';
import { eyreton } from './southIsland/canterbury/eyreton';
import { waiau } from './southIsland/canterbury/waiau';
import { whitecliffs } from './southIsland/canterbury/whitecliffs';
import { littleRiver } from './southIsland/canterbury/littleRiver';
import { otagoCentral } from './southIsland/otago/otagoCentral';
import { oxford } from './southIsland/canterbury/oxford';
import { riccartonRacecourse } from './southIsland/canterbury/riccartonRacecourse';
import { ngaparaTokarahi } from './southIsland/otago/ngaparaTokarahi';
import { waimate } from './southIsland/canterbury/waimate';
import { nelson } from './southIsland/nelson';
import { christchurchTrams } from './southIsland/trams/christchurchTrams';
import { tyndallTramway } from './southIsland/canterbury/tyndallTramway';
import { cityRailLink } from './northIsland/cityRailLink';
import { blenheimRiverside } from './southIsland/marlborough/blenheimRiverside';
import { eastern } from './northIsland/eastern';
import { johnsonville } from './northIsland/johnsonville';
import { kapiti } from './northIsland/kapiti';
import { south } from './northIsland/south';
import { west } from './northIsland/west';
import { wellingtonCableCar } from './northIsland/trams/wellingtonCableCar';
import { northAucklandTrams } from './northIsland/trams/northAucklandTrams';
import { aucklandHeritageTrams } from './northIsland/trams/aucklandHeritageTrams';
import { dunedinCableTrams } from './southIsland/trams/dunedinCableTrams';
import { ferrymeadTram } from './southIsland/trams/ferrymead';
import { drivingCreek } from './northIsland/drivingCreek';
import { WellingtonTrams } from './northIsland/trams/wellingtonTrams.ts';

export const newZealandLines: Line[] = [
  manukau,
  onehunga,
  waiuku,
  missionBush,
  waitara,
  raetihi,
  northAuckland,
  wairarapa,
  opunake,
  northIslandMainTrunk,
  foxton,
  gracefield,
  newPlymouthTrams,
  martonNewPlymouth,
  whanganui,
  palmerstonNorthGisborne,
  ahuriri,
  greytown,
  cambridge,
  eastCoastMainTrunk,
  gisborneTrams,
  napierTrams,
  taneatua,
  thames,
  kinleith,
  rotorua,
  stratfordOkahukura,
  ngatapa,
  glenAfton,
  moutohora,
  sulphurPoint,
  mountMaunganui,
  whanganuiTrams,
  taonui,
  murupara,
  opua,
  mountEgmont,
  donnellysCrossing,
  dargaville,
  okaihau,
  teAro,
  onerahi,
  ...aucklandTrams,
  kumeuRiverhead,
  kimihiaColliery,
  whakataneBoardMills,
  glenMassey,
  huttPark,
  mainSouthLine,
  bluff,
  rewanui,
  rapahoe,
  ross,
  connsCreek,
  blackball,
  capeFoulwind,
  seddonville,
  stillwaterWestport,
  midland,
  tuatapere,
  wairio,
  kingston,
  mossburn,
  tokanui,
  wyndham,
  hedgehope,
  invercargillTrams,
  waimeaPlains,
  waikaia,
  waikaka,
  catlinsRiver,
  kaitangata,
  tapanui,
  dunedinTrams,
  portChalmers,
  lyttelton,
  fairlie,
  southbridge,
  mainNorthLine,
  roxburgh,
  methven,
  dunbackMakareao,
  fernhill,
  oceanBeach,
  kurow,
  outram,
  waltonPark,
  shagPoint,
  mountSomers,
  eyreton,
  waiau,
  whitecliffs,
  littleRiver,
  otagoCentral,
  oxford,
  riccartonRacecourse,
  ngaparaTokarahi,
  waimate,
  nelson,
  ...christchurchTrams,
  tyndallTramway,
  cityRailLink,
  blenheimRiverside,
  eastern,
  johnsonville,
  kapiti,
  south,
  west,
  wellingtonCableCar,
  northAucklandTrams,
  aucklandHeritageTrams,
  dunedinCableTrams,
  ferrymeadTram,
  drivingCreek,
  ...WellingtonTrams
];
