import { Line } from "../../trackTypes";

const penroseOnehunga = [
  [-36.9095, 174.8153],
  [-36.9102, 174.8155],
  [-36.9105, 174.8155],
  [-36.9110, 174.8154],
  [-36.9117, 174.8150],
  [-36.9123, 174.8143],
  [-36.9137, 174.8109],
  [-36.9143, 174.8098],
  [-36.9210, 174.8000],
  [-36.9234, 174.7973],
  [-36.9238, 174.7967],
  [-36.9243, 174.7954],
  [-36.9244, 174.7944],
  [-36.9250, 174.7875],
  [-36.9251, 174.7871],
  [-36.9253, 174.7868],
  [-36.9256, 174.7865],
  [-36.9262, 174.7860],
];

const onehungaOnehungaWharf = [
  [-36.9256, 174.7865],
  [-36.9258, 174.7865],
  [-36.9260, 174.7865],
  [-36.9263, 174.7866],
  [-36.9287, 174.7879],
  [-36.9293, 174.7880],
  [-36.9298, 174.7880],
  [-36.9303, 174.7880],
  [-36.9309, 174.7876],
  [-36.9313, 174.7871],
  [-36.9316, 174.7865],
  [-36.9318, 174.7861],
  [-36.9320, 174.7859],
  [-36.9327, 174.7852],
  [-36.9328, 174.7850],
  [-36.9329, 174.7847],
  [-36.9331, 174.7826]
];

export const onehunga: Line = {
  name: 'Onehunga',
  state: 'NZ',
  segments: [
    {
      segments: [penroseOnehunga],
      history: {
        opened: {
          date: '1873-12-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2006-01-01',
          status: 'closed'
        }, {
          date: '2010-09-18',
          status: 'open'
        }, {
          date: '2014-04-28',
          electrified: {
            current: 'AC',
            isOverhead: true
          }
        }]
      }
    },
    {
      segments: [onehungaOnehungaWharf],
      history: {
        opened: {
          date: '1878-11-28',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'heavy'
        },
        trackChange: [{
          date: '2006-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
