import { Line } from "../../../../trackTypes";

const williamSt = [
  [-36.86809, 174.75389],
  [-36.86816, 174.75375],
  [-36.86823, 174.75366],
  [-36.86833, 174.75357],
  [-36.87205, 174.75228],
  [-36.87385, 174.75169],
  [-36.88252, 174.74891]
];

const mountRoskill = [
  [-36.88252, 174.74891],
  [-36.88532, 174.74802],
  [-36.88766, 174.74726],
  [-36.89099, 174.74618],
  [-36.89488, 174.74493],
  [-36.89788, 174.74395],
  [-36.89967, 174.74337],
  [-36.90237, 174.74247],
  [-36.91263, 174.73916],
  [-36.91910, 174.73705]
];

export const line4: Line = {
  name: 'Line 4',
  state: 'NZ',
  segments: [
    {
      segments: [williamSt],
      history: {
        opened: {
          date: '1908-08-28',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-09-11',
          status: 'closed'
        }]
      }
    },
    {
      segments: [mountRoskill],
      history: {
        opened: {
          date: '1930-02-02',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-09-11',
          status: 'closed'
        }]
      }
    }
  ]
}
