import { Line } from "../../../../trackTypes";

const greyLynn = [
  [-36.85650, 174.74686],
  [-36.85648, 174.74676],
  [-36.85648, 174.74669],
  [-36.85649, 174.74661],
  [-36.85651, 174.74650],
  [-36.85673, 174.74604],
  [-36.85679, 174.74588],
  [-36.85685, 174.74567],
  [-36.85689, 174.74543],
  [-36.85719, 174.74122],
  [-36.85719, 174.74104],
  [-36.85717, 174.74089],
  [-36.85711, 174.74070],
  [-36.85642, 174.73968],
  [-36.85631, 174.73957],
  [-36.85614, 174.73944],
  [-36.85495, 174.73891],
  [-36.85484, 174.73882],
  [-36.85473, 174.73867],
  [-36.85465, 174.73850],
  [-36.85460, 174.73833],
  [-36.85459, 174.73821],
  [-36.85460, 174.73807],
  [-36.85467, 174.73760],
  [-36.85468, 174.73743],
  [-36.85467, 174.73728],
  [-36.85463, 174.73713],
  [-36.85362, 174.73433],
  [-36.85360, 174.73413],
  [-36.85361, 174.73399],
  [-36.85364, 174.73385],
  [-36.85371, 174.73370],
  [-36.85535, 174.73012],
  [-36.85543, 174.73003],
  [-36.85550, 174.72999],
  [-36.85558, 174.72997],
  [-36.85570, 174.72999],
  [-36.85642, 174.73018],
  [-36.85655, 174.73021],
  [-36.85831, 174.73039],
  [-36.85844, 174.73044],
  [-36.85856, 174.73052],
  [-36.85864, 174.73061],
  [-36.85963, 174.73249],
  [-36.85970, 174.73259],
  [-36.85977, 174.73264],
  [-36.85986, 174.73267],
  [-36.86194, 174.73303],
  [-36.86200, 174.73307],
  [-36.86204, 174.73312],
  [-36.86209, 174.73321]
];

export const line11: Line = {
  name: 'Line 11',
  state: 'NZ',
  segments: [
    {
      segments: [greyLynn],
      history: {
        opened: {
          date: '1931-02-08',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1951-11-09',
          status: 'closed'
        }]
      }
    }
  ]
}
