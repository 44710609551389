import { Line } from "../../../../trackTypes";

const city = [
  [-41.28910, 174.77475],
  [-41.28916, 174.77474],
  [-41.28923, 174.77475],
  [-41.28927, 174.77476],
  [-41.29028, 174.77564],
  [-41.29113, 174.77676],
  [-41.29141, 174.77714],
  [-41.29300, 174.77991],
  [-41.29310, 174.78012],
  [-41.29361, 174.78172],
  [-41.29366, 174.78195],
  [-41.29397, 174.78351],
  [-41.29394, 174.78370],
  [-41.29389, 174.78381],
  [-41.29384, 174.78392],
  [-41.29373, 174.78404]
];

const orientalBay = [
  [-41.29333, 174.78426],
  [-41.29326, 174.78436],
  [-41.29318, 174.78460],
  [-41.29309, 174.78482],
  [-41.29286, 174.78513],
  [-41.29245, 174.78560],
  [-41.29235, 174.78573],
  [-41.29185, 174.78640],
  [-41.29179, 174.78650],
  [-41.29175, 174.78662],
  [-41.29173, 174.78679],
  [-41.29173, 174.78719],
  [-41.29161, 174.78873],
  [-41.29159, 174.78891],
  [-41.29152, 174.78911],
  [-41.29072, 174.79103],
  [-41.29069, 174.79116],
  [-41.29070, 174.79126],
  [-41.29072, 174.79136],
  [-41.29124, 174.79250],
  [-41.29133, 174.79289],
  [-41.29136, 174.79322],
  [-41.29133, 174.79412],
  [-41.29130, 174.79436],
  [-41.29092, 174.79617],
  [-41.29086, 174.79635],
  [-41.29077, 174.79650],
  [-41.29068, 174.79662],
  [-41.29006, 174.79708],
  [-41.28935, 174.79741]
];

export const line9: Line = {
  name: 'Line 9',
  state: 'NZ',
  segments: [
    {
      segments: [city, orientalBay],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1950-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
