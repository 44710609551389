import { Line } from "../../../../trackTypes";

const calgarySt = [
  [-36.87153, 174.74729],
  [-36.87157, 174.74719],
  [-36.87161, 174.74712],
  [-36.87168, 174.74706],
  [-36.87258, 174.74681],
  [-36.87275, 174.74670],
  [-36.87287, 174.74655],
  [-36.87294, 174.74638],
  [-36.87298, 174.74618],
  [-36.87298, 174.74600],
  [-36.87273, 174.74472],
  [-36.87273, 174.74458],
  [-36.87275, 174.74442],
  [-36.87304, 174.74358],
  [-36.87310, 174.74343],
  [-36.87318, 174.74326],
  [-36.87376, 174.74240],
  [-36.87387, 174.74228],
  [-36.87397, 174.74219],
  [-36.87444, 174.74191],
  [-36.87457, 174.74185],
  [-36.87561, 174.74153],
  [-36.87801, 174.74075],
  [-36.88396, 174.73882],
  [-36.88937, 174.73708],
  [-36.89186, 174.73628]
];

const owairaka = [
  [-36.89186, 174.73628],
  [-36.89197, 174.73625],
  [-36.89207, 174.73626],
  [-36.89282, 174.73651],
  [-36.89292, 174.73652],
  [-36.89304, 174.73651],
  [-36.89313, 174.73647],
  [-36.89682, 174.73366],
  [-36.89685, 174.73357],
  [-36.89687, 174.73347],
  [-36.89687, 174.73337],
  [-36.89683, 174.73324],
  [-36.89352, 174.72651]
];

const edenPark = [
  [-36.87561, 174.74153],
  [-36.87569, 174.74159],
  [-36.87573, 174.74164],
  [-36.87618, 174.74365]
];

export const line6: Line = {
  name: 'Line 6',
  state: 'NZ',
  segments: [
    {
      segments: [calgarySt],
      history: {
        opened: {
          date: '1925-03-02',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1954-08-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [owairaka],
      history: {
        opened: {
          date: '1931-02-21',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1954-08-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [edenPark],
      history: {
        opened: {
          date: '1925-09-12',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1954-07-31',
          status: 'closed'
        }]
      }
    }
  ]
}
