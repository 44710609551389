import { line1 } from "./wellingtonTrams/line1";
import { line2 } from "./wellingtonTrams/line2";
import { line3 } from "./wellingtonTrams/line3";
import { line4 } from "./wellingtonTrams/line4";
import { line5 } from "./wellingtonTrams/line5";
import { line6 } from "./wellingtonTrams/line6";
import { line7 } from "./wellingtonTrams/line7";
import { line8 } from "./wellingtonTrams/line8";
import { line9 } from "./wellingtonTrams/line9";

export const WellingtonTrams = [
  line1,
  line2,
  line3,
  line4,
  line5,
  line6,
  line7,
  line8,
  line9
];
