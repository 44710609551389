import { Line } from "../../trackTypes";

const ravington = [
  [-36.73701, 175.50380],
  [-36.73699, 175.50455],
  [-36.73698, 175.50464],
  [-36.73696, 175.50471],
  [-36.73694, 175.50485],
  [-36.73694, 175.50518],
  [-36.73697, 175.50528],
  [-36.73703, 175.50532],
  [-36.73713, 175.50532],
  [-36.73721, 175.50528],
  [-36.73742, 175.50501],
  [-36.73749, 175.50496],
  [-36.73763, 175.50488],
  [-36.73820, 175.50466],
  [-36.73829, 175.50465],
  [-36.73849, 175.50470],
  [-36.73858, 175.50478],
  [-36.73862, 175.50493],
  [-36.73856, 175.50523],
  [-36.73856, 175.50535],
  [-36.73859, 175.50547],
  [-36.73871, 175.50567],
  [-36.73872, 175.50577],
  [-36.73867, 175.50586],
  [-36.73858, 175.50587],
  [-36.73850, 175.50581],
  [-36.73845, 175.50574],
  [-36.73843, 175.50559],
  [-36.73840, 175.50514],
  [-36.73836, 175.50503],
  [-36.73828, 175.50499],
  [-36.73821, 175.50499],
  [-36.73808, 175.50502],
  [-36.73794, 175.50513],
  [-36.73783, 175.50536],
  [-36.73781, 175.50553],
  [-36.73776, 175.50563],
  [-36.73770, 175.50570],
  [-36.73733, 175.50581],
  [-36.73724, 175.50587],
  [-36.73706, 175.50616],
  [-36.73694, 175.50628],
  [-36.73684, 175.50635]
];

const ravingtonZigZag = [
  [-36.73694, 175.50628],
  [-36.73716, 175.50614]
];

const ravingtonZigZagEnd = [
  [-36.73716, 175.50614],
  [-36.73737, 175.50603],
  [-36.73752, 175.50595]
];

const ravingtonZigZagTunnelNo1 = [
  [-36.73737, 175.50603],
  [-36.73730, 175.50611],
  [-36.73710, 175.50644],
  [-36.73697, 175.50655],
  [-36.73680, 175.50661]
];

const tunnelNo1 = [
  [-36.73680, 175.50661],
  [-36.73668, 175.50661],
  [-36.73656, 175.50652],
  [-36.73652, 175.50640]
];

const tunnel1Tunnel2 = [
  [-36.73652, 175.50640],
  [-36.73642, 175.50620],
  [-36.73631, 175.50613],
  [-36.73617, 175.50616]
];

const tunnelNo2 = [
  [-36.73617, 175.50616],
  [-36.73609, 175.50620]
];

const hokiMai = [
  [-36.73609, 175.50620],
  [-36.73600, 175.50627],
  [-36.73594, 175.50629],
  [-36.73586, 175.50628],
  [-36.73580, 175.50629],
  [-36.73566, 175.50640],
  [-36.73559, 175.50643],
  [-36.73551, 175.50642],
  [-36.73541, 175.50636],
  [-36.73531, 175.50623],
  [-36.73526, 175.50611],
  [-36.73526, 175.50599],
  [-36.73529, 175.50592],
  [-36.73535, 175.50590],
  [-36.73544, 175.50593],
  [-36.73550, 175.50604],
  [-36.73551, 175.50617],
  [-36.73545, 175.50631],
  [-36.73545, 175.50638],
  [-36.73549, 175.50643],
  [-36.73556, 175.50646],
  [-36.73564, 175.50645],
  [-36.73577, 175.50638],
  [-36.73582, 175.50637],
  [-36.73590, 175.50642],
  [-36.73599, 175.50650]
];

const zigZag2BottomPoints = [
  [-36.73599, 175.50650],
  [-36.73610, 175.50652],
  [-36.73622, 175.50648],
  [-36.73630, 175.50649],
  [-36.73634, 175.50652],
  [-36.73636, 175.50660],
  [-36.73635, 175.50668],
  [-36.73631, 175.50687],
  [-36.73631, 175.50688],
  [-36.73633, 175.50696],
  [-36.73638, 175.50701],
  [-36.73651, 175.50707],
  [-36.73658, 175.50719],
  [-36.73663, 175.50739],
  [-36.73668, 175.50746],
  [-36.73673, 175.50750],
  [-36.73687, 175.50752],
  [-36.73690, 175.50756],
  [-36.73694, 175.50764],
  [-36.73698, 175.50794],
  [-36.73703, 175.50805],
  [-36.73713, 175.50818],
  [-36.73725, 175.50827],
  [-36.73742, 175.50833],
  [-36.73750, 175.50843],
  [-36.73753, 175.50851],
  [-36.73751, 175.50859],
  [-36.73745, 175.50863],
  [-36.73733, 175.50867],
  [-36.73722, 175.50878],
  [-36.73710, 175.50902],
  [-36.73702, 175.50907],
  [-36.73695, 175.50909],
  [-36.73673, 175.50909],
  [-36.73655, 175.50914],
  [-36.73625, 175.50926],
  [-36.73606, 175.50930]
];

const zigZag2 = [
  [-36.73625, 175.50926],
  [-36.73635, 175.50918],
  [-36.73644, 175.50907],
  [-36.73660, 175.50897],
  [-36.73676, 175.50896],
  [-36.73684, 175.50891],
  [-36.73685, 175.50887],
  [-36.73688, 175.50874],
  [-36.73693, 175.50866],
  [-36.73706, 175.50857],
  [-36.73722, 175.50844]
];

const zigZag3Tunnel3 = [
  [-36.73706, 175.50857],
  [-36.73701, 175.50855]
];

const tunnelNo3 = [
  [-36.73701, 175.50855],
  [-36.73687, 175.50855],
  [-36.73670, 175.50852],
  [-36.73656, 175.50844]
];

const zigZag3 = [
  [-36.73656, 175.50844],
  [-36.73646, 175.50839],
  [-36.73637, 175.50831],
  [-36.73631, 175.50820],
  [-36.73626, 175.50798],
  [-36.73618, 175.50788],
  [-36.73606, 175.50785],
  [-36.73590, 175.50787],
  [-36.73581, 175.50794],
  [-36.73572, 175.50809],
  [-36.73566, 175.50813],
  [-36.73556, 175.50816],
  [-36.73541, 175.50829],
  [-36.73534, 175.50830],
  [-36.73526, 175.50825],
  [-36.73519, 175.50813],
  [-36.73508, 175.50807],
  [-36.73495, 175.50795],
  [-36.73490, 175.50787],
  [-36.73488, 175.50770],
  [-36.73490, 175.50750],
  [-36.73494, 175.50738]
];

const eyefullTower = [
  [-36.73488, 175.50770],
  [-36.73484, 175.50785],
  [-36.73488, 175.50799],
  [-36.73494, 175.50807],
  [-36.73500, 175.50831],
  [-36.73506, 175.50846],
  [-36.73531, 175.50863],
  [-36.73563, 175.50869],
  [-36.73571, 175.50867],
  [-36.73579, 175.50863],
  [-36.73588, 175.50861],
  [-36.73620, 175.50865]
];

const branch = [
  [-36.73749, 175.50496],
  [-36.73762, 175.50483],
  [-36.73771, 175.50472],
  [-36.73781, 175.50465],
  [-36.73803, 175.50454],
  [-36.73816, 175.50441],
  [-36.73837, 175.50432]
];

export const drivingCreek: Line = {
  name: 'Driving Creek',
  state: 'NZ',
  segments: [
    {
      segments: [ravington, ravingtonZigZag, branch],
      history: {
        opened: {
          date: '1982-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        }
      }
    },
    {
      segments: [
        ravingtonZigZagEnd,
        ravingtonZigZagTunnelNo1,
        {
          segment: tunnelNo1,
          type: 'tunnel'
        },
        tunnel1Tunnel2,
        {
          segment: tunnelNo2,
          type: 'tunnel'
        },
        hokiMai
      ],
      history: {
        opened: {
          date: '1991-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        }
      }
    },
    {
      segments: [zigZag2BottomPoints, zigZag2],
      history: {
        opened: {
          date: '1995-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        }
      }
    },
    {
      segments: [zigZag2BottomPoints, zigZag2],
      history: {
        opened: {
          date: '1995-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        }
      }
    },
    {
      segments: [
        zigZag3Tunnel3,
        {
          segment: tunnelNo3,
          type: 'tunnel'
        },
        zigZag3
      ],
      history: {
        opened: {
          date: '2001-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        }
      }
    },
    {
      segments: [eyefullTower],
      history: {
        opened: {
          date: '2003-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'industrialTram'
        }
      }
    }
  ]
}
