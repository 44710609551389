import { Line } from "../../../../trackTypes";

const thorndon = [
  [-41.26887, 174.78094],
  [-41.26912, 174.78090],
  [-41.26934, 174.78095],
  [-41.26969, 174.78101],
  [-41.27002, 174.78115],
  [-41.27228, 174.78229],
  [-41.27249, 174.78242],
  [-41.27428, 174.78355],
  [-41.27443, 174.78361],
  [-41.27454, 174.78363],
  [-41.27471, 174.78362],
  [-41.27508, 174.78348],
  [-41.27531, 174.78333],
  [-41.27553, 174.78305],
  [-41.27570, 174.78275],
  [-41.27586, 174.78240],
  [-41.27600, 174.78194],
  [-41.27613, 174.78165],
  [-41.27629, 174.78141],
  [-41.27647, 174.78120],
  [-41.27663, 174.78106],
  [-41.27682, 174.78093],
  [-41.27768, 174.78049],
  [-41.27777, 174.78036],
  [-41.27786, 174.78020]
];

const city = [
  [-41.27786, 174.78020],
  [-41.27809, 174.77971],
  [-41.27995, 174.77624],
  [-41.28008, 174.77610],
  [-41.28020, 174.77597],
  [-41.28038, 174.77590],
  [-41.28062, 174.77585],
  [-41.28181, 174.77606],
  [-41.28197, 174.77606],
  [-41.28211, 174.77603],
  [-41.28294, 174.77564],
  [-41.28323, 174.77553],
  [-41.28359, 174.77546],
  [-41.28369, 174.77549],
  [-41.28377, 174.77557],
  [-41.28381, 174.77567],
  [-41.28430, 174.77727],
  [-41.28438, 174.77739],
  [-41.28445, 174.77747],
  [-41.28455, 174.77757],
  [-41.28843, 174.77833],
  [-41.28856, 174.77829],
  [-41.28987, 174.77764],
  [-41.29000, 174.77768],
  [-41.29008, 174.77775],
  [-41.29029, 174.77806],
  [-41.29174, 174.78066],
  [-41.29185, 174.78090],
  [-41.29265, 174.78338],
  [-41.29270, 174.78351],
  [-41.29306, 174.78426],
  [-41.29314, 174.78431],
  [-41.29324, 174.78431],
  [-41.29333, 174.78426],
  [-41.29373, 174.78404],
  [-41.29550, 174.78304],
  [-41.29933, 174.78090]
];

const newtown = [
  [-41.29933, 174.78090],
  [-41.29941, 174.78090],
  [-41.29954, 174.78092],
  [-41.29967, 174.78104],
  [-41.29974, 174.78122],
  [-41.29984, 174.78149],
  [-41.29994, 174.78164],
  [-41.30006, 174.78173],
  [-41.30020, 174.78176],
  [-41.30037, 174.78173],
  [-41.30121, 174.78122],
  [-41.30131, 174.78109],
  [-41.30138, 174.78095],
  [-41.30140, 174.78080],
  [-41.30137, 174.78062],
  [-41.30123, 174.78007],
  [-41.30122, 174.77997],
  [-41.30125, 174.77991],
  [-41.30128, 174.77985],
  [-41.30134, 174.77979],
  [-41.30140, 174.77973],
  [-41.30715, 174.77791],
  [-41.30723, 174.77791],
  [-41.30731, 174.77791],
  [-41.31081, 174.77901],
  [-41.31097, 174.77903],
  [-41.31150, 174.77913],
  [-41.31167, 174.77917],
  [-41.31172, 174.77919],
  [-41.31907, 174.78148],
  [-41.31913, 174.78154],
  [-41.31916, 174.78161],
  [-41.31917, 174.78170],
  [-41.31887, 174.78344],
  [-41.31881, 174.78377],
  [-41.31882, 174.78389],
  [-41.31885, 174.78398],
  [-41.31891, 174.78405],
  [-41.31899, 174.78406],
  [-41.31906, 174.78405],
  [-41.31911, 174.78398],
  [-41.31913, 174.78389],
  [-41.31913, 174.78380],
  [-41.31910, 174.78373],
  [-41.31904, 174.78366],
  [-41.31893, 174.78360],
  [-41.31891, 174.78356],
  [-41.31888, 174.78351],
  [-41.31887, 174.78344]
];

const station = [
  [-41.27786, 174.78020],
  [-41.27786, 174.78029],
  [-41.27790, 174.78033],
  [-41.27796, 174.78034],
  [-41.27920, 174.77942],
  [-41.28011, 174.77891],
  [-41.28092, 174.77839],
  [-41.28161, 174.77802],
  [-41.28168, 174.77803],
  [-41.28175, 174.77807],
  [-41.28179, 174.77815],
  [-41.28195, 174.77862],
  [-41.28195, 174.77867],
  [-41.28192, 174.77875],
  [-41.28186, 174.77883]
];

const bypass = [
  [-41.28092, 174.77839],
  [-41.28103, 174.77839],
  [-41.28109, 174.77841],
  [-41.28118, 174.77846],
  [-41.28163, 174.77885],
  [-41.28170, 174.77888],
  [-41.28179, 174.77887],
  [-41.28186, 174.77883]
];

export const line1: Line = {
  name: 'Line 1',
  state: 'NZ',
  segments: [
    {
      segments: [thorndon, newtown, station, bypass],
      history: {
        opened: {
          date: '1904-06-30',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1964-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [city],
      history: {
        opened: {
          date: '1878-08-24',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram' //steam, horse on 1882-01
        },
        trackChange: [{
          date: '1904-06-30',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        }, {
          date: '1964-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
