import { Line } from "../../../../trackTypes";

const customsStreetConnection = [
  [-36.84460, 174.76638],
  [-36.84472, 174.76685]
];

const parnellRoad = [
  [-36.84449, 174.76673],
  [-36.84461, 174.76675],
  [-36.84467, 174.76679],
  [-36.84472, 174.76685], //point
  [-36.84547, 174.76968],
  [-36.84551, 174.76986],
  [-36.84554, 174.77010],
  [-36.84554, 174.77044],
  [-36.84556, 174.77076],
  [-36.84561, 174.77107],
  [-36.84585, 174.77195],
  [-36.84594, 174.77216],
  [-36.84607, 174.77238],
  [-36.84624, 174.77261],
  [-36.84766, 174.77391],
  [-36.84791, 174.77408],
  [-36.84795, 174.77411],
  [-36.84820, 174.77425],
  [-36.84830, 174.77430],
  [-36.84871, 174.77444],
  [-36.84898, 174.77453],
  [-36.84934, 174.77457],
  [-36.84970, 174.77456],
  [-36.85026, 174.77448],
  [-36.85038, 174.77448],
  [-36.85045, 174.77449],
  [-36.85052, 174.77454],
  [-36.85060, 174.77464],
  [-36.85075, 174.77494],
  [-36.85209, 174.77827],
  [-36.85220, 174.77841],
  [-36.85232, 174.77853],
  [-36.85377, 174.77943],
  [-36.85738, 174.78167],
  [-36.85747, 174.78174],
  [-36.85891, 174.78288],
  [-36.85898, 174.78293],
  [-36.85905, 174.78295],
  [-36.85914, 174.78297],
  [-36.86031, 174.78283],
  [-36.86044, 174.78280],
  [-36.86056, 174.78274],
  [-36.86206, 174.78189],
  [-36.86221, 174.78180],
  [-36.86234, 174.78168],
  [-36.86456, 174.77928],
  [-36.86468, 174.77919],
  [-36.86485, 174.77911],
  [-36.86779, 174.77808]
];

const carlawPark = [
  [-36.85075, 174.77494],
  [-36.85083, 174.77502],
  [-36.85096, 174.77505],
  [-36.85110, 174.77504],
  [-36.85123, 174.77500],
  [-36.85145, 174.77482],
  [-36.85322, 174.77308]
];

const railwayLoop = [
  [-36.84795, 174.77411],
  [-36.84799, 174.77420],
  [-36.84800, 174.77428],
  [-36.84801, 174.77440],
  [-36.84784, 174.77479],
  [-36.84781, 174.77494],
  [-36.84775, 174.77584],
  [-36.84776, 174.77591],
  [-36.84780, 174.77596],
  [-36.84785, 174.77598],
  [-36.84834, 174.77602],
  [-36.84841, 174.77600],
  [-36.84845, 174.77595],
  [-36.84867, 174.77525],
  [-36.84880, 174.77471],
  [-36.84887, 174.77462],
  [-36.84892, 174.77458],
  [-36.84898, 174.77453]
];

const railwayJunction1 = [
  [-36.84880, 174.77471],
  [-36.84879, 174.77461],
  [-36.84877, 174.77454],
  [-36.84871, 174.77444]
];

const railwayJunction2 = [
  [-36.84801, 174.77440],
  [-36.84805, 174.77433],
  [-36.84812, 174.77428],
  [-36.84820, 174.77425]
];

export const line10: Line = {
  name: 'Line 10',
  state: 'NZ',
  segments: [
    {
      segments: [customsStreetConnection],
      history: {
        opened: {
          date: '1903-01-31',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1954-11-04',
          status: 'closed'
        }]
      }
    },
    {
      segments: [parnellRoad],
      history: {
        opened: {
          date: '1903-01-31',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1956-12-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [railwayLoop, railwayJunction1, railwayJunction2],
      history: {
        opened: {
          date: '1930-11-16',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1956-12-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [carlawPark],
      history: {
        opened: {
          date: '1929-10-05',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1956-12-22',
          status: 'closed'
        }]
      }
    }
  ]
}
