import { Line } from "../../../../trackTypes";
// * crawford rd o1915 c1961 (kilbrine)

const crawfordRoad = [
  [-41.31351, 174.77976],
  [-41.31360, 174.77986],
  [-41.31364, 174.77995],
  [-41.31367, 174.78006],
  [-41.31359, 174.78079],
  [-41.31265, 174.78615],
  [-41.31263, 174.78632],
  [-41.31263, 174.78833],
  [-41.31266, 174.78857],
  [-41.31271, 174.78873],
  [-41.31280, 174.78891],
  [-41.31293, 174.78912],
  [-41.31305, 174.78926],
  [-41.31317, 174.78936],
  [-41.31358, 174.78955],
  [-41.31371, 174.78963],
  [-41.31382, 174.78974],
  [-41.31388, 174.78985],
  [-41.31407, 174.79045],
  [-41.31413, 174.79059],
  [-41.31419, 174.79070],
  [-41.31427, 174.79081],
  [-41.31438, 174.79089],
  [-41.31445, 174.79091],
  [-41.31454, 174.79091],
  [-41.31464, 174.79088],
  [-41.31475, 174.79082],
  [-41.31515, 174.79054],
  [-41.31524, 174.79049],
  [-41.31533, 174.79046],
  [-41.31544, 174.79046],
  [-41.31555, 174.79050],
  [-41.31568, 174.79057],
  [-41.31577, 174.79066],
  [-41.31588, 174.79079],
  [-41.31591, 174.79087],
  [-41.31592, 174.79097],
  [-41.31591, 174.79111],
  [-41.31579, 174.79179],
  [-41.31578, 174.79190],
  [-41.31579, 174.79198],
  [-41.31583, 174.79206],
  [-41.31588, 174.79213],
  [-41.31622, 174.79247],
  [-41.31632, 174.79259],
  [-41.31667, 174.79320],
  [-41.31674, 174.79328],
  [-41.31680, 174.79332],
  [-41.31686, 174.79336],
  [-41.31752, 174.79355],
  [-41.31758, 174.79358],
  [-41.31761, 174.79361],
  [-41.31764, 174.79369],
  [-41.31788, 174.79487],
  [-41.31792, 174.79493],
  [-41.31797, 174.79495],
  [-41.31802, 174.79496]
];

const loop = [
  [-41.31802, 174.79496],
  [-41.31980, 174.79435],
  [-41.31984, 174.79437],
  [-41.31988, 174.79441],
  [-41.31992, 174.79445],
  [-41.32011, 174.79542],
];

const lyallBay = [
  [-41.32011, 174.79542],
  [-41.32016, 174.79548],
  [-41.32022, 174.79552],
  [-41.32028, 174.79554],
  [-41.32057, 174.79546],
  [-41.32069, 174.79544],
  [-41.32535, 174.79538],
  [-41.32898, 174.79533],
  [-41.32907, 174.79528],
  [-41.32916, 174.79522],
  [-41.32925, 174.79512],
  [-41.32990, 174.79377],
  [-41.32995, 174.79361],
  [-41.32998, 174.79346],
  [-41.32997, 174.79194],
  [-41.33000, 174.79186],
  [-41.33003, 174.79181],
  [-41.33009, 174.79177],
  [-41.33109, 174.79175],
  [-41.33112, 174.79179],
  [-41.33116, 174.79186],
  [-41.33116, 174.79197],
  [-41.33112, 174.79210],
  [-41.33066, 174.79281],
  [-41.33011, 174.79347],
  [-41.32990, 174.79377]
];

const connection1 = [
  [-41.31802, 174.79496],
  [-41.31778, 174.79505]
];

const connection2 = [
  [-41.32011, 174.79542],
  [-41.32016, 174.79575]
];

export const line6: Line = {
  name: 'Line 6',
  state: 'NZ',
  segments: [
    {
      segments: [crawfordRoad],
      history: {
        opened: {
          date: '1915-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1961-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [loop],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1961-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [lyallBay],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1960-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [connection1, connection2],
      history: {
        opened: {
          date: '1911-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
