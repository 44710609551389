import { Line } from "../../../../trackTypes";

const centralRoad = [
  [-36.86390, 174.76099],
  [-36.86415, 174.76089],
  [-36.86490, 174.76067],
  [-36.86495, 174.76067],
  [-36.86508, 174.76058],
  [-36.86515, 174.76051],
  [-36.86562, 174.75988],
  [-36.86578, 174.75964],
  [-36.86588, 174.75941],
  [-36.86591, 174.75920],
  [-36.86595, 174.75861],
  [-36.86597, 174.75841],
  [-36.86603, 174.75817],
  [-36.86620, 174.75773],
  [-36.86632, 174.75755],
  [-36.86700, 174.75666],
  [-36.86758, 174.75581],
  [-36.86765, 174.75563],
  [-36.86774, 174.75535],
  [-36.86809, 174.75389],
  [-36.86908, 174.75011],
  [-36.86917, 174.74993],
  [-36.86940, 174.74947],
  [-36.86974, 174.74896],
  [-36.86988, 174.74880],
  [-36.87140, 174.74754],
  [-36.87147, 174.74742],
  [-36.87153, 174.74729],
  [-36.87156, 174.74719],
  [-36.87159, 174.74688],
  [-36.87163, 174.74663],
  [-36.87189, 174.74524]
];

const morningside = [
  [-36.87189, 174.74524],
  [-36.87193, 174.74506],
  [-36.87271, 174.74249],
  [-36.87277, 174.74225],
  [-36.87291, 174.74146],
  [-36.87297, 174.74123],
  [-36.87303, 174.74106],
  [-36.87318, 174.74081],
  [-36.87326, 174.74061],
  [-36.87329, 174.74042],
  [-36.87352, 174.73835],
  [-36.87384, 174.73624],
  [-36.87389, 174.73608],
  [-36.87396, 174.73592],
  [-36.87490, 174.73458]
];

const gladstoneRoad = [
  [-36.87490, 174.73458],
  [-36.87501, 174.73440],
  [-36.87509, 174.73419],
  [-36.87555, 174.73257],
  [-36.87561, 174.73244],
  [-36.87568, 174.73232],
  [-36.88004, 174.72583],
  [-36.88014, 174.72567],
  [-36.88019, 174.72554],
  [-36.88194, 174.72035],
  [-36.88199, 174.72016],
  [-36.88230, 174.71790],
  [-36.88235, 174.71775],
  [-36.88240, 174.71765],
  [-36.88248, 174.71753],
  [-36.88363, 174.71633],
  [-36.88375, 174.71617],
  [-36.88401, 174.71572]
];

const avondale = [
  [-36.88401, 174.71572],
  [-36.88410, 174.71554],
  [-36.88418, 174.71543],
  [-36.88428, 174.71530],
  [-36.88524, 174.71433],
  [-36.89266, 174.70670],
  [-36.89283, 174.70656],
  [-36.89312, 174.70637],
  [-36.89324, 174.70627],
  [-36.89517, 174.70424],
  [-36.89533, 174.70403],
  [-36.89633, 174.70217],
  [-36.89642, 174.70196],
  [-36.89647, 174.70175],
  [-36.89653, 174.70133],
  [-36.89652, 174.70122],
  [-36.89649, 174.70115],
  [-36.89645, 174.70110],
  [-36.89640, 174.70105],
  [-36.89428, 174.70092],
  [-36.89420, 174.70088],
  [-36.89416, 174.70082],
  [-36.89415, 174.70075],
  [-36.89414, 174.70066],
  [-36.89456, 174.69927]
];

export const line7: Line = {
  name: 'Line 7',
  state: 'NZ',
  segments: [
    {
      segments: [centralRoad],
      history: {
        opened: {
          date: '1903-05-02',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1956-01-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [morningside],
      history: {
        opened: {
          date: '1912-07-08',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1956-01-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [gladstoneRoad],
      history: {
        opened: {
          date: '1915-09-01',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1956-01-13',
          status: 'closed'
        }]
      }
    },
    {
      segments: [avondale],
      history: {
        opened: {
          date: '1932-01-31',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1956-01-13',
          status: 'closed'
        }]
      }
    }
  ]
}
