import { Line } from "../../../../trackTypes";

const city = [
  [-41.28987, 174.77764],
  [-41.29132, 174.77682],
  [-41.29462, 174.77496],
  [-41.29761, 174.77330],
  [-41.29766, 174.77331],
  [-41.29770, 174.77334],
  [-41.29773, 174.77339],
  [-41.29837, 174.77534],
  [-41.29841, 174.77540],
  [-41.29846, 174.77543],
  [-41.29855, 174.77543],
  [-41.30123, 174.77399],
  [-41.30137, 174.77395],
  [-41.30152, 174.77395],
  [-41.30169, 174.77400],
  [-41.30301, 174.77470],
  [-41.30321, 174.77477],
  [-41.30439, 174.77504],
  [-41.30454, 174.77506],
  [-41.30714, 174.77512],
  [-41.30724, 174.77518],
  [-41.30732, 174.77527],
  [-41.30737, 174.77542],
  [-41.30736, 174.77555],
  [-41.30711, 174.77662],
  [-41.30709, 174.77679],
  [-41.30709, 174.77695],
  [-41.30721, 174.77776],
  [-41.30724, 174.77784],
  [-41.30731, 174.77791]
];

const junction = [
  [-41.29113, 174.77676],
  [-41.29120, 174.77681],
  [-41.29124, 174.77682],
  [-41.29132, 174.77682]
];

const vivianStreet = [
  [-41.29462, 174.77496],
  [-41.29471, 174.77497],
  [-41.29476, 174.77500],
  [-41.29483, 174.77506],
  [-41.29704, 174.78196],
  [-41.29710, 174.78203],
  [-41.29714, 174.78206],
  [-41.29721, 174.78208]
];

const islandBay = [
  [-41.31167, 174.77917],
  [-41.31183, 174.77914],
  [-41.31200, 174.77908],
  [-41.31398, 174.77848],
  [-41.31510, 174.77814],
  [-41.31519, 174.77812],
  [-41.31528, 174.77811],
  [-41.31657, 174.77804],
  [-41.31680, 174.77802],
  [-41.31825, 174.77796],
  [-41.31972, 174.77787],
  [-41.31979, 174.77784],
  [-41.31985, 174.77779],
  [-41.31988, 174.77772],
  [-41.31989, 174.77763],
  [-41.31985, 174.77553],
  [-41.31987, 174.77544],
  [-41.31989, 174.77538],
  [-41.31993, 174.77533],
  [-41.31997, 174.77529],
  [-41.32147, 174.77520],
  [-41.32468, 174.77504],
  [-41.32500, 174.77502],
  [-41.32521, 174.77498],
  [-41.32594, 174.77483],
  [-41.32769, 174.77441],
  [-41.32791, 174.77435],
  [-41.32871, 174.77405],
  [-41.32883, 174.77401],
  [-41.32899, 174.77398],
  [-41.33306, 174.77357],
  [-41.33348, 174.77350],
  [-41.33545, 174.77330],
  [-41.33566, 174.77326],
  [-41.33585, 174.77320],
  [-41.34240, 174.77055],
  [-41.34281, 174.77036]
];

export const line3: Line = {
  name: 'Line 3',
  state: 'NZ',
  segments: [
    {
      segments: [city, islandBay, vivianStreet],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1963-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [junction],
      history: {
        opened: {
          date: '1905-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1950-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
