import { Line } from "../../../../trackTypes";

const tinakoriRoad = [
  [-41.27895, 174.77807],
  [-41.27882, 174.77819],
  [-41.27873, 174.77822],
  [-41.27865, 174.77823],
  [-41.27854, 174.77820],
  [-41.27785, 174.77777],
  [-41.27778, 174.77774],
  [-41.27769, 174.77773],
  [-41.27276, 174.77795],
  [-41.27253, 174.77794],
  [-41.27191, 174.77781],
  [-41.27184, 174.77777],
  [-41.27178, 174.77771],
  [-41.27178, 174.77758],
  [-41.27179, 174.77750],
  [-41.27182, 174.77742],
  [-41.27894, 174.76910]//
];

const karoriStart = [
  [-41.27964, 174.77683],
  [-41.27958, 174.77688],
  [-41.27949, 174.77692],
  [-41.27940, 174.77696],
  [-41.27934, 174.77697],
  [-41.27906, 174.77691],
  [-41.27899, 174.77688],
  [-41.27894, 174.77681],
  [-41.27891, 174.77672],
  [-41.27872, 174.77522],
  [-41.27865, 174.77493],
  [-41.27856, 174.77466],
  [-41.27849, 174.77453],
  [-41.27819, 174.77412],
  [-41.27808, 174.77393],
  [-41.27799, 174.77376],
  [-41.27792, 174.77344],
  [-41.27790, 174.77327],
  [-41.27792, 174.77305],
  [-41.27796, 174.77285],
  [-41.27804, 174.77266],
  [-41.27813, 174.77251],
  [-41.27841, 174.77209],
  [-41.27855, 174.77195],
  [-41.27913, 174.77143],
  [-41.27921, 174.77134],
  [-41.27927, 174.77122],
  [-41.27928, 174.77112],
  [-41.27927, 174.77098],
  [-41.27888, 174.76939],
  [-41.27889, 174.76929],
  [-41.27890, 174.76922],
  [-41.27894, 174.76910],
  [-41.28038, 174.76738],
  [-41.28049, 174.76724],
  [-41.28061, 174.76704],
  [-41.28221, 174.76364],
  [-41.28230, 174.76349],
  [-41.28242, 174.76335],
  [-41.28292, 174.76295],
  [-41.28301, 174.76285],
  [-41.28309, 174.76276],
  [-41.28418, 174.76123],
  [-41.28527, 174.75951],
  [-41.28584, 174.75842],
  [-41.28592, 174.75832],
  [-41.28602, 174.75826],
  [-41.28612, 174.75822],
  [-41.28713, 174.75821],
  [-41.28724, 174.75816],
  [-41.28733, 174.75811],
  [-41.28796, 174.75760],
  [-41.28808, 174.75757],
  [-41.28821, 174.75757],
  [-41.28840, 174.75762],
  [-41.28853, 174.75760],
  [-41.28860, 174.75752],
  [-41.28865, 174.75737],
  [-41.28863, 174.75725],
  [-41.28857, 174.75712],
  [-41.28850, 174.75705],
  [-41.28837, 174.75705],
  [-41.28771, 174.75727],
  [-41.28752, 174.75736],
  [-41.28725, 174.75749],
  [-41.28714, 174.75749],
  [-41.28707, 174.75745],
  [-41.28699, 174.75735],
  [-41.28696, 174.75721],
  [-41.28698, 174.75708],
  [-41.28703, 174.75697],
  [-41.28746, 174.75646],
  [-41.28751, 174.75636],
  [-41.28752, 174.75624],
  [-41.28750, 174.75611]
];

const karoriTunnel = [
  [-41.28750, 174.75611],
  [-41.28732, 174.75517]
];

const karoriJunction = [
  [-41.28732, 174.75517],
  [-41.28727, 174.75495],
  [-41.28722, 174.75486],
  [-41.28717, 174.75480],
  [-41.28709, 174.75475],
  [-41.28700, 174.75472],
  [-41.28693, 174.75471]
];

const karori = [
  [-41.28693, 174.75471],
  [-41.28539, 174.75465],
  [-41.28526, 174.75462],
  [-41.28514, 174.75456],
  [-41.28482, 174.75426],
  [-41.28476, 174.75416],
  [-41.28472, 174.75402],
  [-41.28455, 174.75315],
  [-41.28449, 174.75297],
  [-41.28440, 174.75279],
  [-41.28406, 174.75238],
  [-41.28396, 174.75230],
  [-41.28389, 174.75225],
  [-41.28376, 174.75221],
  [-41.28294, 174.75213],
  [-41.28284, 174.75215],
  [-41.28274, 174.75218],
  [-41.28266, 174.75224],
  [-41.28255, 174.75235],
  [-41.28246, 174.75238],
  [-41.28180, 174.75250],
  [-41.28172, 174.75249],
  [-41.28164, 174.75245],
  [-41.28158, 174.75239],
  [-41.28154, 174.75229],
  [-41.28142, 174.75186],
  [-41.28137, 174.75177],
  [-41.28101, 174.75125],
  [-41.28096, 174.75114],
  [-41.28096, 174.75108],
  [-41.28096, 174.75100],
  [-41.28099, 174.75090],
  [-41.28145, 174.75023],
  [-41.28150, 174.75011],
  [-41.28171, 174.74940],
  [-41.28173, 174.74921],
  [-41.28177, 174.74815],
  [-41.28179, 174.74798],
  [-41.28183, 174.74777],
  [-41.28346, 174.74194],
  [-41.28627, 174.73218],
  [-41.28634, 174.73189],
  [-41.28658, 174.73077],
  [-41.28694, 174.72769],
  [-41.28695, 174.72747],
  [-41.28684, 174.72603],
  [-41.28685, 174.72589],
  [-41.28692, 174.72556],
  [-41.28698, 174.72541],
  [-41.28736, 174.72466],
  [-41.28758, 174.72429],
  [-41.28772, 174.72415],
  [-41.28845, 174.72370],
  [-41.28861, 174.72357]
];

const northlandStart = [
  [-41.28693, 174.75471],
  [-41.28686, 174.75475],
  [-41.28678, 174.75482],
  [-41.28669, 174.75483],
  [-41.28597, 174.75478],
  [-41.28591, 174.75480],
  [-41.28582, 174.75483],
  [-41.28575, 174.75492],
  [-41.28570, 174.75496],
  [-41.28565, 174.75497],
  [-41.28512, 174.75498],
  [-41.28501, 174.75501],
  [-41.28476, 174.75521]
];

const northlandTunnel = [
  [-41.28476, 174.75521],
  [-41.28418, 174.75599]
];

const northlandEnd = [
  [-41.28418, 174.75599],
  [-41.28335, 174.75710],
  [-41.28332, 174.75717],
  [-41.28329, 174.75726],
  [-41.28328, 174.75790],
  [-41.28326, 174.75802],
  [-41.28323, 174.75813],
  [-41.28313, 174.75826],
  [-41.28303, 174.75833],
  [-41.28294, 174.75836],
  [-41.28288, 174.75836],
  [-41.28281, 174.75833],
  [-41.28266, 174.75827],
  [-41.28257, 174.75825],
  [-41.28249, 174.75825],
  [-41.28213, 174.75827]
];

export const line8: Line = {
  name: 'Line 8',
  state: 'NZ',
  segments: [
    {
      segments: [tinakoriRoad],
      history: {
        opened: {
          date: '1904-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1949-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        karoriStart,
        {
          name: 'Karori Tunnel',
          segment: karoriTunnel,
          type: 'tunnel'
        },
        karoriJunction
      ],
      history: {
        opened: {
          date: '1907-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1954-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [karori],
      history: {
        opened: {
          date: '1907-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1949-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [
        northlandStart,
        {
          name: 'Northland Tunnel',
          segment: northlandTunnel,
          type: 'tunnel'
        },
        northlandEnd
      ],
      history: {
        opened: {
          date: '1929-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1954-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
