import { Line } from "../../../../trackTypes";

const hataitaiStart = [
  [-41.29550, 174.78304],
  [-41.29558, 174.78305],
  [-41.29565, 174.78309],
  [-41.29572, 174.78315],
  [-41.29575, 174.78319],
  [-41.29641, 174.78524],
  [-41.29645, 174.78530],
  [-41.29650, 174.78534],
  [-41.29658, 174.78536],
  [-41.29665, 174.78536],
  [-41.29774, 174.78478],
  [-41.29783, 174.78477],
  [-41.29788, 174.78478],
  [-41.29793, 174.78482],
  [-41.29796, 174.78487],
  [-41.29907, 174.78831],
  [-41.29909, 174.78838],
  [-41.29913, 174.78846],
  [-41.29925, 174.78862]
];

const hataitaiTunnel = [
  [-41.29925, 174.78862],
  [-41.30186, 174.79173]
];

const hataitaiEnd = [
  [-41.30186, 174.79173],
  [-41.30273, 174.79273],
  [-41.30286, 174.79285],
  [-41.30297, 174.79293],
  [-41.30359, 174.79329],
  [-41.30375, 174.79341],
  [-41.30380, 174.79350],
  [-41.30384, 174.79360],
  [-41.30393, 174.79388],
  [-41.30450, 174.79562]
];

const miramarJunction = [
  [-41.30393, 174.79388],
  [-41.30399, 174.79394],
  [-41.30407, 174.79399],
  [-41.30415, 174.79402],
  [-41.30424, 174.79403],
  [-41.30432, 174.79401],
  [-41.30442, 174.79396],
  [-41.30449, 174.79393],
  [-41.30570, 174.79353],
  [-41.30965, 174.79217],
  [-41.30975, 174.79217],
  [-41.30982, 174.79219],
  [-41.30987, 174.79223],
  [-41.31040, 174.79296],
  [-41.31049, 174.79308],
  [-41.31088, 174.79346],
  [-41.31098, 174.79354],
  [-41.31110, 174.79359],
  [-41.31119, 174.79362],
  [-41.31142, 174.79364],
  [-41.31155, 174.79364],
  [-41.31397, 174.79274],
  [-41.31409, 174.79273],
  [-41.31479, 174.79287],
  [-41.31489, 174.79291],
  [-41.31496, 174.79297],
  [-41.31566, 174.79349],
  [-41.31695, 174.79493],
  [-41.31707, 174.79513],
  [-41.31716, 174.79519],
  [-41.31723, 174.79520],
  [-41.31730, 174.79521],
  [-41.31778, 174.79505],
  [-41.31784, 174.79507],
  [-41.31788, 174.79509],
  [-41.31793, 174.79516],
  [-41.31811, 174.79609],
  [-41.31815, 174.79618],
  [-41.31821, 174.79623],
  [-41.31827, 174.79624],
  [-41.32004, 174.79565],
  [-41.32009, 174.79567],
  [-41.32014, 174.79571],
  [-41.32016, 174.79575],
  [-41.32126, 174.80134],
  [-41.32131, 174.80150],
  [-41.32136, 174.80159],
  [-41.32141, 174.80168],
  [-41.32293, 174.80300],
  [-41.32302, 174.80311],
  [-41.32311, 174.80325],
  [-41.32425, 174.80561],
  [-41.32536, 174.80933],
  [-41.32539, 174.80955],
  [-41.32534, 174.81161]
];

const miramar = [
  [-41.32534, 174.81161],
  [-41.32531, 174.81170],
  [-41.32525, 174.81182],
  [-41.32518, 174.81191],
  [-41.32338, 174.81263],
  [-41.32117, 174.81347],
  [-41.32003, 174.81386],
  [-41.31995, 174.81391],
  [-41.31989, 174.81396],
  [-41.31934, 174.81452],
  [-41.31919, 174.81465],
  [-41.31675, 174.81647],
  [-41.31667, 174.81651],
  [-41.31658, 174.81653],
  [-41.31584, 174.81661],
  [-41.31576, 174.81664],
  [-41.31566, 174.81671],
  [-41.31171, 174.81979],
  [-41.31165, 174.81992],
  [-41.31161, 174.82004],
  [-41.31161, 174.82017],
  [-41.31163, 174.82034],
  [-41.31273, 174.82290],
  [-41.31276, 174.82303],
  [-41.31275, 174.82312],
  [-41.31273, 174.82322],
  [-41.31266, 174.82335],
  [-41.31262, 174.82339],
  [-41.31254, 174.82344],
  [-41.31177, 174.82379],
  [-41.30698, 174.82589]
];

export const line5: Line = {
  name: 'Line 5',
  state: 'NZ',
  segments: [
    {
      segments: [
        hataitaiStart,
        {
          name: 'Hataitai Tunnel',
          segment: hataitaiTunnel,
          type: 'tunnel'
        },
        hataitaiEnd
      ],
      history: {
        opened: {
          date: '1907-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1962-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [miramarJunction],
      history: {
        opened: {
          date: '1907-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1958-01-01',
          status: 'closed'
        }]
      }
    },
    {
      segments: [miramar],
      history: {
        opened: {
          date: '1907-01-01',
          tracks: 1,
          gauge: 'narrow',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1957-01-01',
          status: 'closed'
        }]
      }
    }
  ]
}
