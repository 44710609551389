import { Line } from "../../../../trackTypes";

const northRoadEast = [
  [-36.85766, 174.75880],
  [-36.85760, 174.75837],
  [-36.85760, 174.75820],
  [-36.85761, 174.75803],
  [-36.85889, 174.75390],
  [-36.85893, 174.75377],
  [-36.85903, 174.75362],
  [-36.85958, 174.75295]
];

const northRoad = [
  [-36.85958, 174.75295],
  [-36.86463, 174.74662],
  [-36.86472, 174.74642],
  [-36.86701, 174.73960],
  [-36.86702, 174.73945],
  [-36.86701, 174.73929],
  [-36.86696, 174.73914],
  [-36.86611, 174.73781],
  [-36.86602, 174.73771],
  [-36.86589, 174.73765],
  [-36.86463, 174.73744]
];

const pointChevalier = [
  [-36.86463, 174.73744],
  [-36.86449, 174.73737],
  [-36.86439, 174.73727],
  [-36.86433, 174.73716],
  [-36.86429, 174.73702],
  [-36.86391, 174.73462],
  [-36.86392, 174.73439],
  [-36.86395, 174.73421],
  [-36.86400, 174.73404],
  [-36.86408, 174.73389],
  [-36.86420, 174.73372],
  [-36.86465, 174.73322],
  [-36.86478, 174.73306],
  [-36.86489, 174.73291],
  [-36.86653, 174.73052],
  [-36.86780, 174.72847],
  [-36.86789, 174.72827],
  [-36.86801, 174.72791],
  [-36.86810, 174.72745],
  [-36.86814, 174.72668],
  [-36.86816, 174.72640],
  [-36.86817, 174.72611],
  [-36.86796, 174.71936],
  [-36.86796, 174.71905],
  [-36.86797, 174.71878],
  [-36.86798, 174.71857],
  [-36.86802, 174.71829],
  [-36.86885, 174.71421],
  [-36.86893, 174.71392],
  [-36.86905, 174.71359],
  [-36.87064, 174.71018],
  [-36.87065, 174.71006],
  [-36.87065, 174.70996],
  [-36.87060, 174.70988],
  [-36.87054, 174.70983],
  [-36.87049, 174.70979],
  [-36.87038, 174.70975],
  [-36.87027, 174.70975],
  [-36.87019, 174.70975],
  [-36.87001, 174.70978],
  [-36.86989, 174.70978],
  [-36.86972, 174.70974],
  [-36.86959, 174.70968],
  [-36.86942, 174.70956],
  [-36.85996, 174.70238],
  [-36.85986, 174.70233],
  [-36.85976, 174.70232],
  [-36.85966, 174.70233],
  [-36.85954, 174.70236],
  [-36.85869, 174.70269],
  [-36.85648, 174.70350],
  [-36.85441, 174.70415],
  [-36.85430, 174.70419],
  [-36.85421, 174.70419],
  [-36.85264, 174.70423],
  [-36.85236, 174.70424],
  [-36.85229, 174.70419],
  [-36.85227, 174.70413],
  [-36.85226, 174.70405],
  [-36.85227, 174.70396],
  [-36.85231, 174.70389],
  [-36.85236, 174.70384],
  [-36.85243, 174.70383],
  [-36.85248, 174.70387],
  [-36.85251, 174.70393],
  [-36.85253, 174.70405],
  [-36.85255, 174.70412],
  [-36.85260, 174.70420],
  [-36.85264, 174.70423]
];

export const line9: Line = {
  name: 'Line 9',
  state: 'NZ',
  segments: [
    {
      segments: [northRoadEast],
      history: {
        opened: {
          date: '1902-11-02',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-11-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [northRoad],
      history: {
        opened: {
          date: '1903-05-11',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-11-20',
          status: 'closed'
        }]
      }
    },
    {
      segments: [pointChevalier],
      history: {
        opened: {
          date: '1930-07-27',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1953-11-20',
          status: 'closed'
        }]
      }
    }
  ]
}
