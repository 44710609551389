import { Line } from "../../../../trackTypes";

const onehungaWharf = [
  [-36.86976, 174.77762],
  [-36.87149, 174.77705],
  [-36.87171, 174.77699],
  [-36.87203, 174.77697],
  [-36.87360, 174.77708],
  [-36.87525, 174.77718],
  [-36.87546, 174.77716],
  [-36.87560, 174.77712],
  [-36.87926, 174.77628],
  [-36.88067, 174.77597],
  [-36.88119, 174.77590],
  [-36.88363, 174.77572],
  [-36.88608, 174.77559],
  [-36.88835, 174.77532],
  [-36.89063, 174.77494],
  [-36.89199, 174.77469],
  [-36.89324, 174.77448],
  [-36.89354, 174.77442],
  [-36.89547, 174.77389],
  [-36.89561, 174.77387],
  [-36.89570, 174.77388],
  [-36.89662, 174.77394],
  [-36.89673, 174.77394],
  [-36.89682, 174.77392],
  [-36.89947, 174.77310],
  [-36.89960, 174.77310],
  [-36.89977, 174.77315],
  [-36.90786, 174.77516],
  [-36.91170, 174.77606],
  [-36.91214, 174.77625],
  [-36.91627, 174.77834],
  [-36.91634, 174.77842],
  [-36.91638, 174.77849],
  [-36.91641, 174.77858],
  [-36.91643, 174.77866],
  [-36.91595, 174.78419],
  [-36.91596, 174.78429],
  [-36.91599, 174.78437],
  [-36.91603, 174.78443],
  [-36.91609, 174.78449],
  [-36.92799, 174.78607],
  [-36.92810, 174.78611],
  [-36.92819, 174.78616],
  [-36.92991, 174.78754],
  [-36.93005, 174.78762],
  [-36.93022, 174.78768],
  [-36.93031, 174.78769],
  [-36.93041, 174.78768],
  [-36.93058, 174.78761],
  [-36.93076, 174.78750],
  [-36.93091, 174.78736],
  [-36.93105, 174.78720],
  [-36.93116, 174.78704],
  [-36.93128, 174.78685],
  [-36.93137, 174.78664],
  [-36.93147, 174.78634],
  [-36.93154, 174.78599],
  [-36.93157, 174.78552],
  [-36.93159, 174.78522],
  [-36.93158, 174.78487],
  [-36.93151, 174.78382]
];

const ellersie = [
  [-36.87525, 174.77718],
  [-36.87536, 174.77724],
  [-36.87548, 174.77731],
  [-36.87557, 174.77739],
  [-36.87929, 174.78217],
  [-36.87945, 174.78235],
  [-36.87959, 174.78248],
  [-36.88346, 174.78568],
  [-36.88363, 174.78583],
  [-36.88686, 174.78917],
  [-36.88700, 174.78933],
  [-36.89326, 174.79792],
  [-36.89328, 174.79802],
  [-36.89329, 174.79811],
  [-36.89327, 174.79822],
  [-36.89198, 174.79971]
];

const ellersieLoop = [
  [-36.89198, 174.79971],
  [-36.89195, 174.79977],
  [-36.89194, 174.79985],
  [-36.89196, 174.79992],
  [-36.89200, 174.80000],
  [-36.89255, 174.80076],
  [-36.89260, 174.80078],
  [-36.89268, 174.80080],
  [-36.89276, 174.80078],
  [-36.89417, 174.79938],
  [-36.89420, 174.79931],
  [-36.89422, 174.79924],
  [-36.89418, 174.79916],
  [-36.89326, 174.79792]
];

const greenlaneHospital = [
  [-36.89324, 174.77448],
  [-36.89329, 174.77452],
  [-36.89331, 174.77456],
  [-36.89334, 174.77464],
  [-36.89312, 174.78001]
];

const greenlaneHospitalJunction = [
  [-36.89354, 174.77442],
  [-36.89343, 174.77449],
  [-36.89338, 174.77455],
  [-36.89334, 174.77464]
];

export const line3: Line = {
  name: 'Line 3',
  state: 'NZ',
  segments: [
    {
      segments: [onehungaWharf],
      history: {
        opened: {
          date: '1903-09-28',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1956-12-29',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ellersie],
      history: {
        opened: {
          date: '1923-06-17',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1955-01-02',
          status: 'closed'
        }]
      }
    },
    {
      segments: [ellersieLoop],
      history: {
        opened: {
          date: '1923-10-29',
          tracks: 1,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1951-04-06',
          status: 'closed'
        }]
      }
    },
    {
      segments: [greenlaneHospital, greenlaneHospitalJunction],
      history: {
        opened: {
          date: '1939-04-23',
          tracks: 2,
          gauge: 'standard',
          trackType: 'tram',
          electrified: {
            current: 'DC',
            isOverhead: true
          }
        },
        trackChange: [{
          date: '1956-12-28',
          status: 'closed'
        }]
      }
    }
  ]
}
